import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LanguageProvider } from "./components/language/LanguageProvider";
import Application from "./components/common/Application";

// Pages
import Home from "./pages/Home";
import Cybersecurity from "./pages/Cybersecurity";
import InfrastructureAndNetworks from "./pages/InfrastructureAndNetworks";
import DigitalTransformation from "./pages/DigitalTransformation";
import ItConsulting from "./pages/ItConsulting";
import SoftwareManagement from "./pages/SoftwareManagement";
import Development from "./pages/Development";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  return (
    <LanguageProvider>
      <Router>
        <Application>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cybersecurity" element={<Cybersecurity />} />
            <Route
              path="/infrastructure-and-networks"
              element={<InfrastructureAndNetworks />}
            />
            <Route
              path="/digital-transformation"
              element={<DigitalTransformation />}
            />
            <Route path="/it-consulting" element={<ItConsulting />} />
            <Route
              path="/software-management"
              element={<SoftwareManagement />}
            />
            <Route path="/development" element={<Development />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/terms-of-privacy" element={<PrivacyPolicy />} />
          </Routes>
        </Application>
      </Router>
    </LanguageProvider>
  );
}

export default App;