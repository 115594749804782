import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import LucideIcon from "../components/common/LucideIcon";
import { Lang } from "../components/language/LanguageProvider";
import AdvantagesSection from "../components/common/AdvantagesSection";

// Assets
import Logo from "../components/common/Logo";

const Cybersecurity = () => {
  return (
    <>
      <div className="thumb bg-secondary-tc pt-2">
        <Container>
          <div className="section-label d-flex flex-column justify-cybersecurityPageTitle-center align-items-center mb-4">
            <div className="h1" data-aos="fade-right">
              {Lang("cybersecurityPageTitle")}
            </div>
            <p data-aos="fade-left">{Lang("cybersecurityPageDescription")}</p>
          </div>
        </Container>
      </div>
      <section>
        <Container>
          <div className="d-flex flex-column align-items-center gap-2">
            <div className="section-label text-center mb-4">
              <h1 className="h2">{Lang("securityServicesTitle")}</h1>
              <p>{Lang("securityServicesDescription")}</p>
            </div>
            <Row xs={1} md={2} lg={4} className="boxes g-4">
              <Col>
                <div
                  className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2"
                  data-aos="fade-right"
                >
                  <LucideIcon name="ShieldAlert" strokeWidth={1.4} size={26} />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="font-medium font-size-xl">
                      {Lang("innovationTitle")}
                    </span>
                    <span className="font-size-lg">
                      {Lang("innovationDescription")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <div
                  className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2"
                  data-aos="fade-right"
                >
                  <LucideIcon name="Siren" strokeWidth={1.4} size={26} />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="font-medium font-size-xl">
                      {Lang("monitoringTitle")}
                    </span>
                    <span className="font-size-lg">
                      {Lang("monitoringDescription")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <div
                  className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2"
                  data-aos="fade-left"
                >
                  <LucideIcon name="Cloud" strokeWidth={1.4} size={26} />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="font-medium font-size-xl">
                      {Lang("cloudSecurityTitle")}
                    </span>
                    <span className="font-size-lg">
                      {Lang("cloudSecurityDescription")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <div
                  className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2"
                  data-aos="fade-left"
                >
                  <LucideIcon name="FileCheck" strokeWidth={1.4} size={26} />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="font-medium font-size-xl">
                      {Lang("complianceTitle")}
                    </span>
                    <span className="font-size-lg">
                      {Lang("complianceDescription")}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="bg-secondary-tc">
        <Container>
          <div className="text d-flex flex-column align-items-center text-center mb-4">
            <h2 className="h2">{Lang("whyChooseTitle")}</h2>
          </div>
          <Row xs={1} md={2} lg={3} className="boxes g-4">
            <Col data-aos="flip-left">
              <div className="box flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl">
                    {Lang("protectionTitle")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("protectionDescription")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-right">
              <div className="box flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl">
                    {Lang("customSolutionsTitle")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("customSolutionsDescription")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-left">
              <div className="box flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl">
                    {Lang("specializedTeamTitle")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("specializedTeamDescription")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-right">
              <div className="box flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl">
                    {Lang("scalableSolutionsTitle")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("scalableSolutionsDescription")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-left">
              <div className="box flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl">
                    {Lang("seamlessIntegrationTitle")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("seamlessIntegrationDescription")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-right">
              <div className="box flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl">
                    {Lang("specializedSupportTitle")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("specializedSupportDescription")}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <div className="text d-flex flex-column align-items-center text-center mb-4">
            <h3 className="h2">{Lang("securityAspectsTitle")}</h3>
            <p>{Lang("securityAspectsDescription")}</p>
          </div>
          <Row xs={1} md={2} lg={3} className="boxes">
            <Col data-aos="flip-up">
              <div className="box flex-column align-items-center text-center gap-2">
                <div className="bg-primary d-flex align-items-center justify-content-center p-2 rounded-5">
                  <LucideIcon
                    name="Lock"
                    strokeWidth={1.6}
                    size={22}
                    className="text-white"
                  />
                </div>
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl mb-1">
                    {Lang("dataProtectionTitle")}
                  </span>
                  <span className="d-block font-size-sm">
                    {Lang("dataProtectionDescription")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-up">
              <div className="box flex-column align-items-center text-center gap-2">
                <div className="bg-primary d-flex align-items-center justify-content-center p-2 rounded-5">
                  <LucideIcon
                    name="Eye"
                    strokeWidth={1.6}
                    size={22}
                    className="text-white"
                  />
                </div>
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl mb-1">
                    {Lang("securityMonitoringTitle")}
                  </span>
                  <span className="d-block font-size-sm">
                    {Lang("securityMonitoringDescription")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-up">
              <div className="box flex-column align-items-center text-center gap-2">
                <div className="bg-primary d-flex align-items-center justify-content-center p-2 rounded-5">
                  <LucideIcon
                    name="ShieldCheck"
                    strokeWidth={1.6}
                    size={22}
                    className="text-white"
                  />
                </div>
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl mb-1">
                    {Lang("vulnerabilityManagementTitle")}
                  </span>
                  <span className="d-block font-size-sm">
                    {Lang("vulnerabilityManagementDescription")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-up">
              <div className="box flex-column align-items-center text-center gap-2">
                <div className="bg-primary d-flex align-items-center justify-content-center p-2 rounded-5">
                  <LucideIcon
                    name="Save"
                    strokeWidth={1.6}
                    size={22}
                    className="text-white"
                  />
                </div>
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl mb-1">
                    {Lang("backupRecoveryTitle")}
                  </span>
                  <span className="d-block font-size-sm">
                    {Lang("backupRecoveryDescription")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-up">
              <div className="box flex-column align-items-center text-center gap-2">
                <div className="bg-primary d-flex align-items-center justify-content-center p-2 rounded-5">
                  <LucideIcon
                    name="Globe"
                    strokeWidth={1.6}
                    size={22}
                    className="text-white"
                  />
                </div>
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl mb-1">
                    {Lang("networkSecurityTitle")}
                  </span>
                  <span className="d-block font-size-sm">
                    {Lang("networkSecurityDescription")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-up">
              <div className="box flex-column align-items-center text-center gap-2">
                <div className="bg-primary d-flex align-items-center justify-content-center p-2 rounded-5">
                  <LucideIcon
                    name="Key"
                    strokeWidth={1.6}
                    size={22}
                    className="text-white"
                  />
                </div>
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl mb-1">
                    {Lang("accessControlTitle")}
                  </span>
                  <span className="d-block font-size-sm">
                    {Lang("accessControlDescription")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-up">
              <div className="box flex-column align-items-center text-center gap-2">
                <div className="bg-primary d-flex align-items-center justify-content-center p-2 rounded-5">
                  <LucideIcon
                    name="GraduationCap"
                    strokeWidth={1.6}
                    size={22}
                    className="text-white"
                  />
                </div>
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl mb-1">
                    {Lang("securityTrainingTitle")}
                  </span>
                  <span className="d-block font-size-sm">
                    {Lang("securityTrainingDescription")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-up">
              <div className="box flex-column align-items-center text-center gap-2">
                <div className="bg-primary d-flex align-items-center justify-content-center p-2 rounded-5">
                  <LucideIcon
                    name="File"
                    strokeWidth={1.6}
                    size={22}
                    className="text-white"
                  />
                </div>
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl mb-1">
                    {Lang("regulatoryComplianceTitle")}
                  </span>
                  <span className="d-block font-size-sm">
                    {Lang("regulatoryComplianceDescription")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-up">
              <div className="box flex-column align-items-center text-center gap-2">
                <div className="bg-primary d-flex align-items-center justify-content-center p-2 rounded-5">
                  <LucideIcon
                    name="FileChartColumnIncreasing"
                    strokeWidth={1.6}
                    size={22}
                    className="text-white"
                  />
                </div>
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl mb-1">
                    {Lang("riskAssessmentTitle")}
                  </span>
                  <span className="d-block font-size-sm">
                    {Lang("riskAssessmentDescription")}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="p-0">
        <div className="section bg-gray-light position-relative">
          <div className="bg-waves-in-column pt-5 pb-5">
            <Container>
              <div
                className="d-flex flex-column align-items-center gap-2"
                data-aos="zoom-in"
              >
                <div className="logo lg">
                  <Logo />
                </div>
                <div className="section-label mb-0">
                  <h4 className="h2">{Lang("protectNetworkTitle")}</h4>
                  <p>{Lang("protectNetworkDescription")}</p>
                </div>
                <Link to="/contact-us">
                  <Button className="btn-lg btn-primary rounded-5">
                    {Lang("requestConsultationButton")}
                  </Button>
                </Link>
              </div>
            </Container>
          </div>
        </div>
      </section>
      <AdvantagesSection className="pb-4" />
    </>
  );
};

export default Cybersecurity;