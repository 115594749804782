import { icons } from "lucide-react";

const LucideIcon = ({ name, color, strokeWidth, width, height, size, className }) => {
  const setSize = width ? width : size;
  const LucideIcon = icons[name];
  return (
    <LucideIcon
      color={color}
      strokeWidth={strokeWidth}
      size={setSize}
      className={className}
    />
  );
};

export default LucideIcon;