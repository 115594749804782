const language = () => {
    const language = {
        "details": {
            "name": "English",
            "sigle": "en-us",
            "country": "United States"
        },
        "language": {
            // Title
            "pageTitle": "MSXP Connect - Innovative Solutions for the Future of Technology",
            // Links
            "home": "Home",
            "services": "Services",
            "infrastructureAndNetworks": "Infrastructure and Networks",
            "digitalTransformation": "Digital Transformation",
            "itConsulting": "IT Consulting",
            "softwareManagement": "Software Management",
            "development": "Development",
            "msxpConnect": "MSXP Connect",
            "aboutUs": "About Us",
            "contactUs": "Contact Us",
            "termsOfService": "Terms of Service",
            "termsOfPrivacy": "Privacy Policy",
            "loginToAccount": "Login to account",
            "footerDescription": "MSXP Connect: Innovative solutions in software, information security, and infrastructure to transform your business.",
            "emailLabel": "Email",
            "phoneLabel": "Phone",
            "headquartersText": "Headquartered in London, United Kingdom.",
            // Others
            "solutions_heading": "Innovative and Customized Solutions for Your Company.",
            "digital_journey": "Accelerating Your Digital Journey with Expertise in Software, Security, and Infrastructure.",
            "cybersecurity": "Cybersecurity",
            "infrastructure_networks": "Infrastructure and Networks",
            "digital_transformation": "Digital Transformation",
            "it_consulting": "IT Consulting",
            "software_management": "Software Management",
            "software_development_management": "Software Development and Management",
            "information_security": "Information Security",
            "information_security_description": "We protect your data with advanced security solutions, ensuring the confidentiality and integrity of information.",
            "it_consulting_digital_transformation": "IT Consulting and Digital Transformation",
            "it_consulting_digital_transformation_description": "We help your company adopt best practices and technologies to drive digital transformation.",
            "infrastructure_networks_description": "We design and manage networks and infrastructure to ensure operational efficiency and scalability.",
            "project_management": "Project Management",
            "project_management_description": "We manage IT projects with precision and efficiency, ensuring delivery on time and within budget.",
            "technical_support_help_desk": "Technical Support and Help Desk",
            "technical_support_help_desk_description": "We provide 24/7 technical support to resolve issues quickly and keep your operations running smoothly.",
            "image_alt": "IT professional working on a computer",
            "learn_more": "Learn More",
            "custom_plans": "Custom plans developed to meet the unique needs of your business.",
            "recent_innovations": "Recent innovations implemented to keep your company at the technological forefront.",
            "ongoing_support": "Ongoing support to ensure a successful and sustainable digital transformation.",
            "information_security_heading": "Information Security: Protect Your Business from Cyber Threats",
            "software_development_heading": "Software Development and Management: Tailored Solutions for Your Business",
            "complete_lifecycle_management": "Complete Lifecycle Management",
            "scalability_and_flexibility": "Scalability and Flexibility",
            "support_description": "Seamless integration with existing systems and dedicated technical support to resolve issues quickly and maintain business continuity.",
            "infrastructure_networks_heading": "Need Robust Infrastructure and Networks for Your Data Center or Internet Provider? Contact Us Now!",
            "request_free_consultation": "Request a Free Consultation",
            "proven_experience": "Proven Experience",
            "custom_solutions": "Custom Solutions",
            "dedicated_support": "Dedicated Support",
            "carousel_section_title": "Software Development and Management: Tailored Solutions for Your Business",
            "software_development_title": "Software Development and Management: Tailored Solutions for Your Business",
            "software_development_description": "We offer custom software development and comprehensive management to meet the specific needs of your business.",
            "discover_solutions": "Discover Our Solutions",
            "development_description": "Tailored solutions to meet the unique needs of your business.",
            "lifecycle_management": "Complete Lifecycle Management",
            "lifecycle_management_description": "Continuous monitoring from creation to maintenance.",
            "scalability": "Scalability and Flexibility",
            "scalability_description": "Scalable solutions that grow with your company.",
            "technical_support": "Technical Support",
            "technical_support_description": "Dedicated technical support to resolve issues quickly and maintain business continuity.",
            "contact_us_question": "Do you have questions about this service or need a customized solution?",
            "contact_us": "Contact Us",
            "cybersecurity_title": "Information Security: Total Protection for Your Company's Data",
            "cybersecurity_description": "At MSXP Connect, we offer robust information security solutions to protect your data and ensure the integrity and confidentiality of your operations.",
            "learn_more_solutions": "Learn More About Our Solutions",
            "data_protection": "Data Protection",
            "data_protection_description": "Advanced security measures to protect sensitive information from unauthorized access and breaches.",
            "continuous_monitoring": "Continuous Monitoring",
            "continuous_monitoring_description": "Real-time monitoring to identify and mitigate threats before they harm your business.",
            "compliance_audit": "Compliance and Auditing",
            "compliance_audit_description": "Ensuring your company complies with regulations and security standards.",
            "incident_response": "Incident Response",
            "incident_response_description": "Incident response plans to minimize impacts and quickly restore operations.",
            "digital_transformation_title": "Digital Transformation: Creating Websites and Visual Solutions for Your Business",
            "digital_transformation_description": "At MSXP Connect, we drive your business into the digital world with modern and attractive websites, as well as visual solutions like custom icons and banners.",
            "explore_solutions": "Explore Our Solutions",
            "website_creation": "Website Creation",
            "website_creation_description": "Development of responsive, optimized, and visually appealing websites, tailored to your brand’s identity.",
            "icon_design": "Icon Design",
            "icon_design_description": "Development of custom icons that reflect your company's visual identity, ensuring intuitive navigation.",
            "banners_elements": "Banners and Visual Elements",
            "banners_elements_description": "Creation of impactful banners and visual elements that capture the audience's attention and highlight your message.",
            "seo_optimization": "SEO Optimization",
            "seo_optimization_description": "Websites developed with SEO practices to ensure visibility in search engines and attract more qualified visitors.",
            "digital_transformation_question": "Want to know more about our digital transformation solutions?",
            "it_consulting_title": "IT Consulting: Tailored Strategies for Your Business's Technological Success",
            "it_consulting_description": "At MSXP Connect, we offer IT consulting to optimize your technological processes and align your infrastructure with business goals.",
            "learn_more_consulting": "Learn More About Our Consultancies",
            "analysis_diagnosis": "Analysis and Diagnosis",
            "analysis_diagnosis_description": "Identification of strengths and areas for improvement in your IT infrastructure to maximize efficiency and reduce costs.",
            "strategic_planning": "Strategic Planning",
            "strategic_planning_description": "Development of IT plans aligned with your company’s goals, ensuring technology supports your growth.",
            "solution_implementation": "Solution Implementation",
            "solution_implementation_description": "Assistance in implementing technologies and systems that optimize your processes and improve operational performance.",
            "support_monitoring": "Support and Monitoring",
            "support_monitoring_description": "Ongoing support and monitoring services to ensure your IT infrastructure operates efficiently and securely.",
            "consulting_question": "Have questions or need an IT consulting solution?",
            "carousel_item_1": "Software Development and Management",
            "carousel_item_2": "Information Security",
            "carousel_item_3": "Digital Transformation",
            "carousel_item_4": "IT Consulting",
            "cybersecurityPageTitle": "Protect Your Data with Advanced Security Solutions",
            "cybersecurityPageDescription": "At MSXP Connect, we understand that information security is crucial to protecting your business from cyber threats. Our solutions are designed to ensure the integrity, confidentiality, and availability of your data, keeping your company secure and compliant with regulatory standards.",
            "securityServicesTitle": "Our Information Security Services",
            "securityServicesDescription": "Our information security services are designed to protect your company.",
            "innovationTitle": "Continuous Innovation",
            "innovationDescription": "We identify vulnerabilities and provide recommendations to mitigate risks.",
            "monitoringTitle": "Monitoring",
            "monitoringDescription": "We monitor your systems and respond quickly to incidents.",
            "cloudSecurityTitle": "Cloud Security",
            "cloudSecurityDescription": "We protect your cloud data with security and compliance.",
            "complianceTitle": "Compliance",
            "complianceDescription": "We ensure your company meets established security standards.",
            "whyChooseTitle": "Why Choose MSXP Connect for Information Security?",
            "protectionTitle": "24/7 Protection",
            "protectionDescription": "Continuous monitoring for complete protection of your network.",
            "customSolutionsTitle": "Customized Solutions",
            "customSolutionsDescription": "Tailored security solutions adapted to your business.",
            "specializedTeamTitle": "Specialized Team",
            "specializedTeamDescription": "Certified cybersecurity experts.",
            "scalableSolutionsTitle": "Scalable Solutions",
            "scalableSolutionsDescription": "Our solutions grow as your business grows.",
            "seamlessIntegrationTitle": "Seamless Integration",
            "seamlessIntegrationDescription": "We integrate security without disrupting your operations.",
            "specializedSupportDescription": "Expert support available whenever you need it.",
            "securityAspectsTitle": "Key Aspects of Information Security",
            "securityAspectsDescription": "Our approach to information security covers all critical aspects to protect your data and systems against threats.",
            "dataProtectionTitle": "Data Protection",
            "dataProtectionDescription": "We implement measures to protect your data from unauthorized access and leaks.",
            "securityMonitoringTitle": "Security Monitoring",
            "securityMonitoringDescription": "We continuously monitor your networks and systems to detect and respond quickly to threats.",
            "vulnerabilityManagementTitle": "Vulnerability Management",
            "vulnerabilityManagementDescription": "We identify and fix vulnerabilities before they can be exploited by attackers.",
            "backupRecoveryTitle": "Backup and Recovery",
            "backupRecoveryDescription": "We perform regular backups to ensure business continuity.",
            "networkSecurityTitle": "Network Security",
            "networkSecurityDescription": "We provide advanced protection for your networks against intrusions and cyber-attacks.",
            "accessControlTitle": "Access Control",
            "accessControlDescription": "We manage and control access to your systems to ensure security.",
            "securityTrainingTitle": "Security Training",
            "securityTrainingDescription": "We offer training for your team on best practices and threat prevention.",
            "regulatoryComplianceTitle": "Regulatory Compliance",
            "regulatoryComplianceDescription": "We ensure your security practices comply with regulations.",
            "riskAssessmentTitle": "Risk Assessments",
            "riskAssessmentDescription": "We perform risk assessments to identify weaknesses and continuously improve.",
            "logoAltText": "MSXP Connect Logo",
            "protectNetworkTitle": "Ready to Protect Your Network?",
            "protectNetworkDescription": "Don't leave your business vulnerable to cyber attacks. With MSXP Connect's security solutions, you can protect your data, your network, and your business from advanced threats. Contact us today to find out how we can help keep your company secure.",
            "requestConsultationButton": "Request a Free Consultation",
            "imageAltText": "IT professional on computer",
            "itSolutionsTitle": "IT Infrastructure Solutions",
            "itSolutionsDescription": "Enhance the efficiency and reliability of your network with our solutions.",
            "learnMoreButton": "Learn More",
            "highAvailability": "High availability and performance",
            "integratedSecurity": "Integrated security",
            "monitoring": "24/7 monitoring",
            "dedicatedSupport": "Dedicated technical support",
            "chooseMSXPTitle": "Why Choose MSXP Connect for Information Security?",
            "dataCenterManagementTitle": "Data Center Management",
            "dataCenterManagementDesc": "Our data center management solutions are designed to optimize performance and security of your data.",
            "constantMonitoring": "Constant monitoring to identify and resolve issues quickly.",
            "backupSystems": "Backup systems to ensure continuous operation in case of failure.",
            "energyReduction": "Reduced energy consumption for more sustainable operations.",
            "resourceAddition": "Addition of resources as your business grows.",
            "corporateNetworksTitle": "Corporate Networks",
            "corporateNetworksDesc": "We develop corporate networks that ensure efficient and secure communication between your departments, with scalable solutions.",
            "effectiveProtection": "Effective protection against threats and unauthorized access.",
            "failoverSystems": "Guarantee of continuous access with failover systems.",
            "globalIntegration": "Integration of networks across various locations worldwide.",
            "centralizedAdmin": "Centralized administration for easier maintenance and control.",
            "internetProvidersTitle": "Internet Service Providers",
            "internetProvidersDesc": "We offer specialized support for internet providers, ensuring fast, secure, and stable connections.",
            "highPerformance": "High Network Performance: Fast and low-latency connections.",
            "qualifiedAssistance": "Qualified assistance for issues and maintenance.",
            "proactiveMaintenance": "Proactive Maintenance: Regular adjustments to prevent failures.",
            "continuousAnalysis": "Continuous analysis to ensure network stability.",
            "highPerformanceInfrastructureTitle": "Boost Your Business with High-Performance Infrastructure",
            "highPerformanceInfrastructureDesc": "At MSXP Connect, we understand that IT infrastructure is the backbone of any modern organization. That’s why our solutions are designed to provide not only reliability and performance but also the flexibility to grow with your business. With a security-focused approach, our solutions protect your data and ensure regulatory compliance, while facilitating innovation and expansion. Additionally, our dedicated technical support and proactive monitoring ensure that your infrastructure is always optimized and running efficiently. Regardless of your business size or complexity, we are here to help you transform your operations and reach new heights of success.",
            "securityServicesDesc": "Our information security services are designed to protect your business.",
            "networkOptimizationTitle": "Network Optimization",
            "networkOptimizationDesc": "Enhance your network performance with our optimization solutions. We ensure continuous and fast connectivity.",
            "specializedSupportTitle": "Specialized Technical Support",
            "specializedSupportDesc": "Count on our team of experts for dedicated technical support and proactive maintenance.",
            "transformITInfrastructureTitle": "Ready to Transform Your IT Infrastructure?",
            "transformITInfrastructureDesc": "IT infrastructure is the foundation on which your business stands. In a world where agility and efficiency are essential, a robust and well-managed infrastructure can make the difference between success and failure. At MSXP Connect, we offer infrastructure solutions that not only meet current needs.",
            "consultButton": "Request a Free Consultation",
            "digitalTransformationBadge": "Digital Transformation",
            "digitalTransformationTitle": "Transform Your Digital Presence",
            "digitalTransformationDesc": "Explore our digital transformation solutions designed to elevate your brand in the online environment.",
            "logoIdentity": "Logo and Visual Identity",
            "customSoftware": "Custom Software",
            "onlinePresence": "Online Presence",
            "digitalTransformationServicesTitle": "Digital Transformation Services",
            "digitalTransformationServicesDesc": "Explore our full range of digital transformation services, designed to modernize your online presence, optimize your operations, and drive business growth.",
            "webDevelopment": "Website Development",
            "webDevelopmentDesc": "Create modern, functional websites that reflect your brand and meet user needs.",
            "logoDesign": "Logo Design",
            "logoDesignDesc": "Develop logos that capture your company's identity and stand out in the market.",
            "digitalMarketing": "Digital Marketing",
            "digitalMarketingDesc": "Implement effective strategies to enhance your online presence and engage your target audience.",
            "mobileApps": "Mobile Apps",
            "mobileAppsDesc": "Create custom mobile applications to enhance accessibility and interaction with your customers.",
            "seoOptimization": "SEO Optimization",
            "seoOptimizationDesc": "Enhance your website's visibility in search engines to attract new visitors.",
            "dataAnalysis": "Data Analysis",
            "dataAnalysisDesc": "Utilize data to generate valuable insights and make informed decisions about your digital strategies.",
            "systemIntegration": "System Integration",
            "systemIntegrationDesc": "Integrate various platforms and systems to improve operational efficiency and communication between them.",
            "consulting": "Consulting",
            "consultingDesc": "Specialized guidance to help your company implement and manage effective digital changes.",
            "ourExpertiseTitle": "Our Expertise",
            "ourExpertiseDesc": "In today's hyperconnected world, your digital presence is more than just an online showcase; it's the beating heart of your brand. MSXP Connect is here to transform that presence in innovative and impactful ways, offering customized solutions that not only highlight your brand but also strategically position it for success.",
            "customWebsites": "Website Development",
            "customWebsitesDesc": "At MSXP Connect, we create tailor-made websites that reflect your brand's unique identity. We offer comprehensive solutions that ensure attractive design, flawless functionality, and optimization for all devices, providing an exceptional experience for your users.",
            "customSoftwareDesc": "With MSXP Connect, software development is tailored to meet your specific needs. From business applications to custom solutions, our team utilizes the latest technologies to create robust and efficient software.",
            "onlinePresenceDesc": "In the digital age, a company's online presence is crucial to its success. MSXP Connect offers a comprehensive approach to building and managing your online presence, including digital marketing strategies, SEO optimization, and social media management.",
            "operationalEfficiency": "Improved Operational Efficiency",
            "operationalEfficiencyDesc": "Automate processes and reduce human errors with digital solutions, increasing efficiency and productivity across all areas of your business.",
            "customerExperience": "Enhanced Customer Experience",
            "customerExperienceDesc": "Provide a more intuitive and personalized user experience with digital tools that improve customer service and interactions.",
            "advancedAnalytics": "Advanced Analytics and Insights",
            "advancedAnalyticsDesc": "Use data analysis tools to gain deep insights into customer behavior and business performance, enabling more informed decisions.",
            "agilityFlexibility": "Agility and Flexibility",
            "agilityFlexibilityDesc": "Quickly adapt to market changes and new opportunities with digital solutions that offer operational agility and flexibility.",
            "costReduction": "Cost Reduction",
            "costReductionDesc": "Minimize operational costs through the automation and efficiency provided by digital solutions, freeing up resources for other areas.",
            "increasedCompetitiveness": "Increased Competitiveness",
            "increasedCompetitivenessDesc": "Gain a competitive advantage in the market with cutting-edge technologies and digital strategies that set your company apart from the competition.",
            "responsiveDesign": "Responsive Design",
            "responsiveDesignDescription": "websites that adapt to devices, ensuring a good experience.",
            "customSolutions": "Custom Solutions",
            "maintenanceSupport": "Maintenance and Support",
            "maintenanceSupportDescription": "Ongoing support to keep your website running smoothly and up to date.",
            "transformBusiness": "Transform the Way You Do Business",
            "boostBusiness": "Boost Your Business with Our Digital Transformation",
            "digitalTransformationDescription": "Digital transformation is key to modernizing your operations and making the most of emerging technologies. At MSXP Connect, we offer comprehensive solutions covering everything from website development to logo creation and customized online strategies, ensuring your business stays ahead in the digital market.",
            "digitalTransformationSpecialists": "Our digital transformation experts work with you to implement innovative solutions that improve efficiency, customer experience, and your brand's online presence.",
            "customWebSolutions": "Custom web solutions tailored to meet your business's specific needs.",
            "intuitiveInterfaces": "Creation of intuitive and visual interfaces that enhance user experience.",
            "digitalIntegration": "Integration of tools and digital platforms that optimize your operations.",
            "seoPractices": "Enhance your online visibility with effective SEO practices.",
            "logoDesignDescription": "Creation of impactful logos that represent your brand's identity.",
            "colorPalette": "Color Palette",
            "colorPaletteDescription": "Development of a cohesive color palette that reflects your brand's personality.",
            "typography": "Typography",
            "typographyDescription": "Choosing fonts that complement the visual identity and convey the right message.",
            "brandMaterials": "Brand Materials",
            "brandMaterialsDescription": "Creation of graphic materials such as business cards and stationery that reinforce your visual identity.",
            "transformYourBrand": "Transform Your Brand with Visual Identity",
            "developImpactfulVisualIdentity": "Develop an Impactful Visual Identity for Your Business",
            "visualIdentityDescription": "Visual identity is crucial for how your brand is perceived. At MSXP Connect, we create logos and visual identities that make your brand stand out and effectively communicate your values. From logo design to choosing a color palette and typography, we work to ensure your brand has a memorable presence.",
            "visualIdentitySpecialists": "Our specialized designers work with you to create a visual identity that not only grabs attention but also establishes a lasting connection with your target audience.",
            "customLogoDesign": "Custom logo designs that reflect the essence of your brand.",
            "harmoniousColorPalettes": "Harmonious color palettes that help create a consistent visual identity.",
            "carefullySelectedTypography": "Carefully selected typography that complements the visual identity.",
            "brandReinforcementMaterials": "Graphic materials that reinforce your brand's image across all touchpoints.",
            "softwareDevelopment": "Software Development",
            "softwareDevelopmentDescription": "Creating tailored software solutions that meet your specific needs.",
            "systemIntegrationDescription": "Efficient integration of software with existing systems to optimize processes.",
            "cloudSolutions": "Cloud Solutions",
            "cloudSolutionsDescription": "Development and management of cloud applications and services.",
            "securityAndSupport": "Security and Support",
            "securityAndSupportDescription": "Guaranteed security and ongoing support for the proper functioning of your software.",
            "transformYourProcesses": "Transform Your Processes with Custom Software",
            "enhanceYourBusiness": "Enhance Your Business with Customized Software",
            "softwareDescription": "Custom software provides specific solutions to meet your unique business needs. At MSXP Connect, we develop tailor-made software that seamlessly integrates with your processes, increasing efficiency and improving results. From development to integration and support, we ensure your software is always aligned with your business goals.",
            "softwareExperts": "Our experts work closely with you to understand your needs and create software solutions that drive your success.",
            "customSoftwareDevelopment": "Custom software development to meet your unique requirements.",
            "robustSecurity": "Robust security and ongoing support to ensure optimal performance of your software.",
            "socialMediaManagement": "Social Media Management",
            "performanceAnalysis": "Performance Analysis",
            "strengthenDigitalPresence": "Strengthen Your Digital Presence",
            "maximizeVisibility": "Maximize Your Visibility with Effective Online Strategies",
            "webDevelopmentDescription": "Creation of websites and pages that maximize visibility and online interaction.",
            "seoOptimizationDescription": "Strategies to improve your search engine ranking and increase traffic.",
            "socialMediaManagementDescription": "Creation and management of profiles and campaigns on major social platforms.",
            "performanceAnalysisDescription": "Monitoring and analyzing data to adjust strategies and improve results.",
            "digitalPresenceDescription": "A solid online presence is crucial for success in modern business. At MSXP Connect, we offer complete solutions to establish and strengthen your digital presence. From impactful website development to advanced SEO strategies and social media management, we work to ensure your brand stands out and reaches its target audience.",
            "digitalMarketingExperts": "Our digital marketing and web development experts collaborate with you to create and execute strategies that increase visibility, engage your audience, and drive business growth.",
            "impactfulWebsiteDevelopment": "Website development that ensures a striking and functional online presence.",
            "seoOptimizationService": "SEO optimization to improve visibility and attract more qualified visitors.",
            "effectiveSocialMediaManagement": "Effective social media management to engage and expand your audience.",
            "detailedPerformanceAnalysis": "Detailed performance analysis to adjust strategies and improve results.",
            "transformChallengesIntoOpportunities": "Transform Your Challenges into Opportunities with Our IT Consulting",
            "itConsultingDescription": "At MSXP Connect, we offer specialized Information Technology consulting to help your company overcome technological challenges and fully seize digital opportunities.",
            "ourITConsultingServices": "Our IT Consulting Services",
            "exploreOurRangeOfITServices": "Explore our range of IT consulting services",
            "infrastructureAnalysis": "Infrastructure Analysis",
            "evaluateYourCurrentInfrastructure": "Evaluate your current infrastructure to identify areas of improvement and optimization.",
            "strategicPlanning": "Strategic Planning",
            "developTechPlansAlignedWithBusiness": "Develop technology plans aligned with your business objectives.",
            "projectManagement": "Project Management",
            "manageITProjectsEfficiently": "Manage IT projects efficiently and within established timelines.",
            "informationSecurity": "Information Security",
            "implementSecurityMeasures": "Implement security measures to protect your data and systems.",
            "processOptimization": "Process Optimization",
            "improveEfficiencyWithOptimizedProcesses": "Improve your business efficiency with optimized processes.",
            "teamTraining": "Team Training",
            "empowerYourTeamWithTraining": "Empower your team with personalized training in new technologies.",
            "cloudConsulting": "Cloud Consulting",
            "adoptCloudSolutionsForFlexibility": "Adopt cloud solutions that enhance flexibility and scalability.",
            "technicalSupport": "Technical Support",
            "getOngoingTechnicalSupport": "Get ongoing technical support to resolve issues quickly.",
            "maximizeITPotential": "Maximize the Potential of Your IT with Our Specialized Consulting",
            "comprehensivePersonalizedSolutions": "Our IT consulting offers comprehensive and personalized solutions to meet the specific needs of your business.",
            "detailedInfrastructureAssessment": "Detailed assessment of your current infrastructure to identify areas for improvement.",
            "technologyPlansAlignedWithGoals": "Development of technology plans aligned with your business goals.",
            "coordinationAndExecutionOfProjects": "Coordination and execution of IT projects focused on results and deadlines.",
            "robustSecurityMeasures": "Implementation of robust security measures to protect your data and systems.",
            "operationalEfficiencyImprovements": "Improvement of operational efficiency through automation and process enhancement.",
            "personalizedTeamTraining": "Personalized training for your team in new technologies and IT practices.",
            "cloudAdoptionAndManagement": "Adoption and management of cloud solutions that enhance flexibility and scalability.",
            "rapidEffectiveTechnicalSupport": "Rapid and effective technical support to resolve issues and minimize disruptions.",
            "systemsIntegration": "Integration of diverse systems to ensure cohesive and efficient operation.",
            "detailedReportingAndAnalysis": "Provision of detailed reports and analysis to monitor and improve IT performance.",
            "consultingSolutionsForEfficientFuture": "Consulting Solutions for a More Efficient Future",
            "transformHowYourCompanyUsesTechnology": "Our IT consulting is designed to transform the way your company uses technology.",
            "evaluationAndPlanning": "Evaluation and Planning",
            "completeAnalysisAndStrategicPlan": "We conduct a comprehensive analysis of your IT infrastructure and develop a strategic plan to optimize your technological environment.",
            "implementationAndManagement": "Implementation and Management",
            "manageTechSolutionsAndContinuousMonitoring": "We manage the implementation of technological solutions and ensure ongoing monitoring to guarantee everything runs efficiently.",
            "supportAndTraining": "Support and Training",
            "dedicatedTechSupportAndProactiveMaintenance": "Count on our team of experts for dedicated technical support and proactive maintenance.",
            "readyToTransformTechnology": "Ready to Transform Your Technology with Our IT Consulting?",
            "digitalTransformationKeyToInnovation": "Digital transformation is the key to innovation and growth in today’s world. Our experts are ready to help you upgrade and enhance every aspect of your online presence, from website development and logo design to optimizing your digital strategies.",
            "requestFreeConsulting": "Request a Free Consulting",
            "optimizeAndManageSoftwareResources": "Optimize and Manage Your Software Resources Effectively",
            "msxpConnectSoftwareSolutions": "At MSXP Connect, we offer comprehensive software management solutions that ensure your applications and systems are always optimized and aligned with your business needs.",
            "softwareImplementation": "Software Implementation",
            "softwareImplementationDescription": "We assist in selecting the best software solutions for your needs and implement them to ensure a smooth and effective integration with your existing systems.",
            "licenseManagementDescription": "We manage and monitor software licenses to ensure compliance and optimize costs, avoiding legal issues and ensuring proper use of licenses.",
            "maintenanceAndSupport": "Maintenance and Support",
            "maintenanceAndSupportDescription": "We offer continuous technical support and regular maintenance to keep your software running smoothly and address any issues that may arise.",
            "teamTableAlt": "team table",
            "perfectChoiceAndIntegrity": "Perfect Choice and Integrity",
            "findAndImplementBestSolutions": "Find and Implement the Best Solutions",
            "ourSoftwareSelectionProcess": "Our software selection process is thorough and personalized to ensure you choose solutions that best meet your specific needs. We evaluate various vendors and options to ensure the best combination of functionality, cost, and compatibility.",
            "afterSelectionManagement": "After selection, we manage the implementation of the software to ensure smooth integration with your existing systems. We work to minimize any disruption to operations and ensure your team can start using the new software quickly and efficiently.",
            "complianceAndSavings": "Compliance and Savings",
            "efficientLicenseManagement": "Efficient License Management",
            "manageAndMonitorLicenses": "We manage and monitor all your software licenses to ensure legal compliance and maximize the value of your investment. We maintain detailed and up-to-date records, avoiding audit issues and ensuring proper use of licenses.",
            "continuousLicenseAdjustment": "Our team continuously reviews your needs and adjusts licenses as necessary to optimize costs. This includes renegotiating contracts and identifying savings opportunities, ensuring you only pay for what you actually use.",
            "techAlt": "tech",
            "techTableAlt": "tech table",
            "specializedMaintenanceAndSupport": "Specialized Maintenance and Support",
            "proactiveMaintenanceAndContinuousSupport": "Proactive Maintenance and Continuous Support",
            "continuousTechnicalSupport": "We offer continuous technical support to quickly resolve any issues that may arise with your software. Our proactive approach includes regular monitoring and maintenance to identify and address issues before they affect your operations.",
            "regularMaintenance": "In addition to technical support, we perform regular maintenance to ensure your software runs smoothly and stays updated with the latest patches and improvements. This minimizes the risk of failures and maximizes the efficiency of your IT environment.",
            "softwareManagementFeaturesAndBenefits": "Software Management Features and Benefits",
            "exploreSoftwareManagementBenefits": "Explore the various features and benefits that our software management offers to optimize your operations and maximize your technology investment.",
            "efficientSelection": "Efficient Selection",
            "chooseBestSolutions": "Choose the best software solutions for your needs.",
            "quickImplementation": "Quick Implementation",
            "integrateWithoutDisruptions": "Integrate new software without disrupting operations.",
            "licenseManagement": "License Management",
            "controlLicenses": "Manage licenses to avoid legal issues and save costs.",
            "ongoingAssistance": "Continuous assistance to resolve issues and queries.",
            "regularUpdates": "Regular Updates",
            "softwareUpToDate": "Keep your software updated with the latest versions.",
            "customTraining": "Custom Training",
            "continuousEvaluation": "Ongoing assessment to improve software performance.",
            "specializedConsulting": "Specialized Consulting",
            "softwareSolutionsGuidance": "Guidance on software solutions and strategies.",
            "transformSoftwareManagement": "Transform the Way Your Business Manages Software",
            "efficientSoftwareManagement": "Efficient software management is crucial for maximizing your return on investment and ensuring your company is equipped to handle technological challenges with agility. At MSXP Connect, we offer a holistic approach to software management, covering everything from selection and implementation to ongoing maintenance and support. Our team of experts works closely with you to understand your specific needs and provide customized solutions that optimize the performance and functionality of your software.",
            "adoptingOurPractices": "By adopting our management practices, you not only improve operational efficiency and security but also gain a clear view of your technological resources, enabling more informed and strategic decisions. Discover how our expertise can transform your approach to software and drive your company's success.",
            "revolutionizeSoftwareManagement": "Ready to Revolutionize Your Software Management?",
            "specializedSolutions": "At MSXP Connect, we are ready to take your software management to the next level. Our specialized solutions ensure efficient management of all your software resources, from selection and implementation to ongoing support and optimization.",
            "softwareDevelopmentManagement": "Software Development and Management",
            "comprehensiveSolutions": "At MSXP Connect, we offer comprehensive solutions for software development and management, designed to meet the specific needs of your business.",
            "requirementsAnalysis": "Requirements Analysis",
            "requirementsAnalysisDesc": "Gathering and documenting client needs.",
            "designPrototyping": "Design and Prototyping",
            "designPrototypingDesc": "Creating prototypes for visualization and adjustments.",
            "agileDevelopment": "Agile Development",
            "agileDevelopmentDesc": "Agile methodology for rapid delivery and continuous feedback.",
            "implementation": "Implementation",
            "implementationDesc": "Launching the software and training the team.",
            "customSoftwareSolutions": "Custom Software Solutions",
            "completeServiceRange": "We offer a full range of services to ensure that your company's software is developed and managed in an efficient and customized manner.",
            "quickDelivery": "Quick Delivery",
            "continuousFeedback": "Continuous Feedback",
            "seamlessIntegration": "Seamless Integration",
            "efficiencyImprovement": "Efficiency Improvement",
            "continuousSupport": "Continuous Support",
            "24x7Support": "24x7 Support",
            "proactiveMonitoring": "Proactive Monitoring",
            "regularMaintenanceFor": "Regular Maintenance for",
            "continuousEnhancements": "Continuous Enhancements",
            "transformYourBusiness": "Transform Your Business with Our Software Solutions",
            "eachServiceOptimized": "Each service is designed to optimize processes, increase productivity, and ensure you stay ahead in a constantly evolving market.",
            "customDevelopment": "Custom Development",
            "customDevelopmentDescription": "We create software tailored to your company's needs. From conception to implementation, we ensure every detail is adjusted to optimize your processes and drive growth.",
            "projectManagementDescription": "We manage the complete lifecycle of your software project, ensuring deadlines are met and high-quality deliverables. Our meticulous approach minimizes risks and maximizes ROI.",
            "continuousMaintenance": "Continuous Maintenance",
            "continuousMaintenanceDescription": "We offer ongoing support and maintenance to ensure your software runs smoothly. Regular updates and improvements keep you competitive and secure in the market.",
            "msxpConnectServices": "At MSXP Connect, we offer a wide range of services covering all stages of the software lifecycle.",
            "specializedConsultingDescription": "Our experienced consultants work with you to understand your needs and provide software solutions that perfectly align with your strategic goals.",
            "agileDevelopmentDescription": "We use agile methodologies to develop software quickly and efficiently, allowing for continuous adjustments to better meet your business needs.",
            "softwareSecurity": "Software Security",
            "softwareSecurityDescription": "We implement rigorous security measures throughout the development process to protect your data and ensure compliance with regulations.",
            "continuousSupportDescription": "We offer dedicated technical support to keep your software running smoothly, with quick solutions for any issues that arise.",
            "updatesAndMaintenance": "Updates and Maintenance",
            "updatesAndMaintenanceDescription": "We keep your software updated with the latest technologies and improvements, ensuring you stay competitive and secure in the market.",
            "readyToRevolutionizeSoftwareManagement": "Ready to Revolutionize Your Software Management?",
            "msxpConnectReadyDescription": "At MSXP Connect, we are ready to take your software management to a new level. Our specialized solutions ensure efficient management of all your software resources, from selection and implementation to continuous support and optimization.",
            "frequentlyAskedQuestions": "Frequently Asked Questions About Our Software Services",
            "faqDescription": "To help you better understand our services and how we can contribute to your business success, we answer the most common questions we receive from our clients.",
            "whatIsCustomSoftwareDevelopment": "What is custom software development?",
            "customSoftwareDevelopmentDescription": "Custom software development refers to creating software solutions tailored to meet the specific needs of a business or organization. Instead of using off-the-shelf software, we create bespoke tools that align perfectly with your business processes and objectives.",
            "benefitsOfSoftwareIntegration": "What are the benefits of integrating new software with existing systems?",
            "softwareIntegrationBenefits": "Integrating new software with existing systems allows for greater efficiency, automation, and centralization of information. It reduces process redundancy, improves communication across different areas of the company, and streamlines operational management, resulting in time and resource savings.",
            "howDoesTechnicalSupportWork": "How does the continuous technical support offered by MSXP Connect work?",
            "technicalSupportDescription": "MSXP Connect’s continuous technical support ensures that your software solution is always up-to-date and running smoothly. Our team is available to resolve issues, provide updates, and make adjustments as needed, keeping your system’s operation efficient and secure in the long term.",
            "whatIsSoftwareProjectManagement": "What is software project management?",
            "softwareProjectManagementDescription": "Software project management involves planning, executing, and monitoring all stages of software development. At MSXP Connect, we follow agile methodologies to ensure the project is delivered on time, within budget, and with the expected quality, with full transparency at every stage.",
            "howDoesMSXPConnectEnsureDataSecurity": "How does MSXP Connect ensure the security of my data?",
            "dataSecurityDescription": "The security of your data is our top priority. We use advanced security practices such as encryption, continuous monitoring, and regular backups to protect your data against external threats. Additionally, we strictly adhere to best practices for compliance to ensure that your solution meets data privacy and security standards.",
            "aboutOurCompany": "About Our Company",
            "welcomeMessage": "Welcome to MSXP Connect",
            "ourHistory": "Our History",
            "getToKnowUs": "Get to Know MSXP Connect",
            "exploreOurServices": "Explore Our Services",
            "teamImageAlt": "Team member working on computer",
            "ourExpertiseDescription": "MSXP Connect is a leading company in technological solutions, specializing in software development, information security, and IT infrastructure. With an experienced and dedicated team, we offer personalized services to help businesses achieve their goals and optimize their processes.",
            "ourCommitmentTitle": "Our Commitment",
            "ourCommitmentDescription": "Our commitment to innovation and quality allows us to deliver effective solutions tailored to the specific needs of each client. At MSXP Connect, your satisfaction is our priority, and we work tirelessly to ensure the success of every project.",
            "missionValuesVision": "Mission, Values, and Vision for a Successful Future",
            "commitmentTitle": "Commitment",
            "commitmentDescription": "Our commitment ensures that each project is executed with the highest precision and excellence, meeting our clients' expectations.",
            "customerFocusTitle": "Customer Focus",
            "customerFocusDescription": "We place our clients at the center of everything we do, tailoring our services to meet their needs and exceeding their expectations.",
            "dedicatedTeamTitle": "Dedicated Team",
            "dedicatedTeamDescription": "Our team is composed of passionate and specialized professionals, committed to delivering exceptional results and driving the success of our clients.",
            "readyToAnswer": "We are ready to answer all your questions and resolve any issues you may have with us.",
            "phone": "Phone",
            "callNow": "Call Now",
            "sendEmail": "Send an Email",
            "address": "Address",
            "addressDescription": "Based in London, United Kingdom.",
            "viewOnMap": "View on Map",
            "preferToFillOutForm": "Or if you prefer, fill out the form below",
            "name": "Name",
            "namePlaceholder": "Enter your name",
            "nameValidation": "Please enter your name.",
            "email": "Email",
            "emailPlaceholder": "Enter your email",
            "emailValidation": "Please enter a valid email.",
            "message": "Message",
            "messagePlaceholder": "How can we help?",
            "messageValidation": "Please enter a message.",
            "sendMessage": "Send Message",
            "requiredFields": "All fields are required. By submitting the form, you agree to the Terms and Conditions and Privacy Policy.",
            "contractBadge": "Contract",
            "contractTitle": "See a transparent contract we have prepared for you",
            "contractDescription": "We are committed to providing a quality service and a fair contract for both parties.",
            "privacyPolicy": "Privacy Policy",
            "copyrightText": `Copyright © ${new Date().getFullYear()} MSXP Connect. All rights reserved.`,
        }
    };

    return language;
};

export default language();