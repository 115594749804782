import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Lang } from "../components/language/LanguageProvider";
import Contract from "../components/contract/Contract";

const TermsOfService = () => {
  return (
    <>
      <div className="thumb bg-secondary-tc pt-2">
        <Container>
          <div className="d-flex flex-column align-items-center">
            <div className="section-label d-flex flex-column justify-content-center align-items-center mb-3">
              <span className="badge rounded-pill text-bg-color mb-3">
                {Lang("contractBadge")}
              </span>
              <div className="h1">{Lang("contractTitle")}</div>
              <p>{Lang("contractDescription")}</p>
            </div>
            <div className="btn-group m-auto">
              <Link
                  to="/terms-of-service"
                  className="btn btn-lg btn-primary"
              >
                  {Lang("termsOfService")}
              </Link>
              <Link
                to="/terms-of-privacy"
                className="btn btn-lg btn-outline-primary bg-outline-primary btn-light"
              >
                {Lang("privacyTerms")}
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <div className="section">
        <Container>
          <Contract className="row contract g-4">
            <Contract.Links title="Terms of Service">
              <Contract.Link target="terms-and-conditions">
                Terms and Conditions
              </Contract.Link>
              <Contract.Link target="interpretation-and-definitions">
                Interpretation and Definitions
              </Contract.Link>
              <Contract.Link target="acknowledgment">
                Acknowledgment
              </Contract.Link>
              <Contract.Link target="links-to-other-websites">
                Links to Other Websites
              </Contract.Link>
              <Contract.Link target="termination">Termination</Contract.Link>
              <Contract.Link target="limitation-of-liability">
                Limitation of Liability
              </Contract.Link>
              <Contract.Link target="disclaimer-as-is-and-as-available">
                Disclaimer
              </Contract.Link>
              <Contract.Link target="governing-law">
                Governing Law
              </Contract.Link>
              <Contract.Link target="disputes-resolution">
                Dispute Resolution
              </Contract.Link>
              <Contract.Link target="for-european-union-eu-users">
                For European Union (EU) Users
              </Contract.Link>
              <Contract.Link target="united-states-legal-compliance">
                United States Legal Compliance
              </Contract.Link>
              <Contract.Link target="severability-and-waiver">
                Severability and Waiver
              </Contract.Link>
              <Contract.Link target="translation-interpretation">
                Translation Interpretation
              </Contract.Link>
              <Contract.Link target="changes-to-these-terms-and-conditions">
                Changes to These Terms
              </Contract.Link>
              <Contract.Link target="contact-us">Contact Us</Contract.Link>
            </Contract.Links>
            <Contract.Documents>
              <Contract.Document id="terms-and-conditions">
                <div className="h2">Terms of Service</div>
                <p>Last updated: September 2, 2024</p>
                <p>
                  Please read these terms and conditions carefully before using
                  Our Service.
                </p>
              </Contract.Document>
              <Contract.Document id="interpretation-and-definitions">
                <div className="h2">Interpretation and Definitions</div>
                <div className="h3">Interpretation</div>
                <p>
                  Words with an initial capital letter have meanings defined
                  under the following conditions. The following definitions will
                  have the same meaning regardless of whether they appear in the
                  singular or plural.
                </p>
                <div className="h3">Definitions</div>
                <p>For the purposes of these Terms and Conditions:</p>
                <ul>
                  <li>
                    <p>
                      <strong>Affiliate</strong> means an entity that controls,
                      is controlled by, or is under common control with a party,
                      where "control" means ownership of 50% or more of the
                      shares, equity interest, or other voting securities for
                      the election of directors or other management authority.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Country</strong> refers to: United Kingdom
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Company</strong> (referred to as "the Company",
                      "We", "Us" or "Our" in this Agreement) refers to MSXP
                      Connect, London, United Kingdom.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Device</strong> means any device that can access
                      the Service, such as a computer, a mobile phone, or a
                      digital tablet.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Service</strong> refers to the Website.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Terms and Conditions</strong> (also referred to as
                      "Terms") means these Terms and Conditions which form the
                      entire agreement between You and the Company regarding the
                      use of the Service. This Terms and Conditions agreement
                      was created with the help of the{" "}
                      <a
                        href="https://www.freeprivacypolicy.com/free-terms-and-conditions-generator/"
                        target="_blank"
                      >
                        Free Terms and Conditions Generator
                      </a>
                      .
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Third-Party Social Media Services</strong> means
                      any services or content (including data, information,
                      products, or services) provided by third parties that may
                      be displayed, included, or made available by the Service.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Website</strong> refers to MSXP Connect,
                      accessible from{" "}
                      <a
                        href="https://msxp.ca"
                        rel="external nofollow noopener"
                        target="_blank"
                      >
                        https://msxp.ca
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>You</strong> means the individual who accesses or
                      uses the Service, or the company, or other legal entity on
                      behalf of which such individual is accessing or using the
                      Service, as applicable.
                    </p>
                  </li>
                </ul>
              </Contract.Document>
              <Contract.Document id="acknowledgment">
                <div className="h2">Acknowledgment</div>
                <p>
                  These are the Terms and Conditions governing the use of this
                  Service and the agreement that operates between You and the
                  Company. These Terms and Conditions establish the rights and
                  obligations of all users in relation to the use of the
                  Service.
                </p>
                <p>
                  Your access and use of the Service are conditioned on your
                  acceptance and compliance with these Terms and Conditions.
                  These Terms and Conditions apply to all visitors, users, and
                  others who access or use the Service.
                </p>
                <p>
                  By accessing or using the Service, You agree to be bound by
                  these Terms and Conditions. If You disagree with any part of
                  these Terms and Conditions, then You may not access the
                  Service.
                </p>
                <p>
                  You represent that you are over 18 years old. The Company does
                  not permit anyone under 18 to use the Service.
                </p>
                <p>
                  Your access and use of the Service are also conditioned on
                  your acceptance and compliance with the Company’s Privacy
                  Policy. Our Privacy Policy describes our policies and
                  procedures regarding the collection, use, and disclosure of
                  your personal information when You use the Application or the
                  Website and informs you about your privacy rights and how the
                  law protects you. Please read our Privacy Policy carefully
                  before using Our Service.
                </p>
              </Contract.Document>
              <Contract.Document id="links-to-other-websites">
                <div className="h2">Links to Other Websites</div>
                <p>
                  Our Service may contain links to websites or services of third
                  parties that are not owned or controlled by the Company.
                </p>
                <p>
                  The Company has no control over, and assumes no responsibility
                  for, the content, privacy policies, or practices of any
                  third-party websites or services. You acknowledge and agree
                  that the Company shall not be responsible or liable, directly
                  or indirectly, for any damage or loss caused or alleged to be
                  caused by or in connection with the use of or reliance on any
                  content, goods, or services available on or through any such
                  websites or services.
                </p>
                <p>
                  We strongly advise you to read the terms and conditions and
                  privacy policies of any third-party websites or services that
                  you visit.
                </p>
              </Contract.Document>
              <Contract.Document id="termination">
                <div className="h2">Termination</div>
                <p>
                  We may terminate or suspend your access immediately, without
                  prior notice or liability, for any reason, including but not
                  limited to if you breach these Terms and Conditions.
                </p>
                <p>
                  Upon termination, your right to use the Service will cease
                  immediately.
                </p>
              </Contract.Document>
              <Contract.Document id="limitation-of-liability">
                <div className="h2">Limitation of Liability</div>
                <p>
                  Notwithstanding any damages you might incur, the Company’s and
                  any of its suppliers' total liability under any provision of
                  these Terms and your sole remedy for all of the foregoing will
                  be limited to the amount actually paid by you through the
                  Service or 100 USD if you have not purchased anything through
                  the Service.
                </p>
                <p>
                  To the maximum extent permitted by applicable law, in no event
                  shall the Company or its suppliers be liable for any special,
                  incidental, indirect, or consequential damages of any kind
                  (including, but not limited to, damages for loss of profits,
                  loss of data or other information, business interruption,
                  personal injury, loss of privacy arising out of or in any way
                  related to the use or inability to use the Service,
                  third-party software and/or third-party hardware used with the
                  Service, or otherwise in connection with any provision of
                  these Terms), even if the Company or any supplier has been
                  advised of the possibility of such damages and even if the
                  remedy fails of its essential purpose.
                </p>
                <p>
                  Some states do not allow the exclusion of implied warranties
                  or limitation of liability for incidental or consequential
                  damages, which means that some or all of the above limitations
                  may not apply to you. In such states, each party’s liability
                  will be limited to the greatest extent permitted by law.
                </p>
              </Contract.Document>
              <Contract.Document id="disclaimer-as-is-and-as-available">
                <div className="h2">"AS IS" and "AS AVAILABLE" Disclaimer</div>
                <p>
                  The Service is provided to You “AS IS” and “AS AVAILABLE” and
                  with all faults and defects without warranty of any kind. To
                  the maximum extent permitted by applicable law, the Company,
                  on its own behalf and on behalf of its Affiliates and its and
                  their respective licensors and service providers, expressly
                  disclaims all warranties, whether express, implied, statutory
                  or otherwise, with respect to the Service, including all
                  implied warranties of merchantability, fitness for a
                  particular purpose, title and non-infringement, and warranties
                  that may arise out of course of dealing, course of
                  performance, usage or trade practice. Without limitation to
                  the foregoing, the Company provides no warranty or
                  undertaking, and makes no representation of any kind that the
                  Service will meet Your requirements, achieve any intended
                  results, be compatible or work with any other software,
                  applications, systems or services, operate without
                  interruption, meet any performance or reliability standards or
                  be error free or that any errors or defects can or will be
                  corrected.
                </p>
                <p>
                  Without limiting the foregoing, neither the Company nor any of
                  its provider makes any representation or warranty of any kind,
                  express or implied: (i) as to the operation or availability of
                  the Service, or the information, content, and materials or
                  products included thereon; (ii) that the Service will be
                  uninterrupted or error-free; (iii) as to the accuracy,
                  reliability, or timeliness of any information or content
                  provided through the Service; or (iv) that the Service, its
                  servers, the content, or e-mails sent from or on behalf of the
                  Company are free of viruses, scripts, trojan horses, worms,
                  malware, timebombs or other harmful components.
                </p>
                <p>
                  Some jurisdictions do not allow the exclusion of certain types
                  of warranties or limitations on applicable statutory rights of
                  a consumer, so some or all of the above exclusions and
                  limitations may not apply to You. But in such a case, the
                  exclusions and limitations set forth in this section will be
                  applied to the greatest extent possible under applicable law.
                </p>
              </Contract.Document>
              <Contract.Document id="governing-law">
                <div className="h2">Governing Law</div>
                <p>
                  The laws of the country, excluding its conflict of laws rules,
                  will govern these Terms and your use of the Service. Your use
                  of the App may also be subject to other local, state,
                  national, or international laws.
                </p>
              </Contract.Document>
              <Contract.Document id="disputes-resolution">
                <div className="h2">Dispute Resolution</div>
                <p>
                  If you have any concerns or disputes about the Service, you
                  agree to first try to resolve the dispute informally by
                  contacting the Company.
                </p>
              </Contract.Document>
              <Contract.Document id="for-european-union-eu-users">
                <div className="h2">For European Union (EU) Users</div>
                <p>
                  If you are a consumer in the European Union, you will benefit
                  from any mandatory provisions of the law of the country in
                  which you reside.
                </p>
              </Contract.Document>
              <Contract.Document id="united-states-legal-compliance">
                <div className="h2">United States Legal Compliance</div>
                <p>
                  You represent and warrant that (i) you are not located in a
                  country that is subject to a U.S. government embargo or has
                  been designated by the U.S. government as a
                  “terrorist-supporting” country, and (ii) you are not listed on
                  any U.S. government list of prohibited or restricted parties.
                </p>
              </Contract.Document>
              <Contract.Document id="severability-and-waiver">
                <div className="h2">Severability and Waiver</div>
                <div className="h3">Severability</div>
                <p>
                  If any provision of these Terms is held to be unenforceable or
                  invalid, such provision will be modified and interpreted to
                  achieve the objectives of that provision to the greatest
                  extent possible under applicable law, and the remaining
                  provisions will continue in full force and effect.
                </p>
                <div className="h3">Waiver</div>
                <p>
                  Except as provided in this document, failure to exercise a
                  right or enforce an obligation under these Terms will not
                  affect a party’s ability to exercise such right or enforce
                  such obligation at any later time, nor will the waiver of a
                  breach constitute a waiver of any subsequent breach.
                </p>
              </Contract.Document>
              <Contract.Document id="translation-interpretation">
                <div className="h2">Translation Interpretation</div>
                <p>
                  These Terms and Conditions may have been translated if we made
                  them available to you in our Service. You agree that the
                  original English text will prevail in the event of a dispute.
                </p>
              </Contract.Document>
              <Contract.Document id="changes-to-these-terms-and-conditions">
                <div className="h2">Changes to These Terms and Conditions</div>
                <p>
                  We reserve the right, at our sole discretion, to modify or
                  replace these Terms at any time. If a revision is material, we
                  will make reasonable efforts to provide at least 30 days'
                  notice before any new terms take effect. What constitutes a
                  material change will be determined at our sole discretion.
                </p>
                <p>
                  By continuing to access or use our Service after those
                  revisions become effective, you agree to be bound by the
                  revised terms. If you do not agree with the new terms, in
                  whole or in part, please stop using the website and the
                  Service.
                </p>
              </Contract.Document>
              <Contract.Document id="contact-us">
                <div className="h2">Contact Us</div>
                <p>
                  If you have any questions about these Terms and Conditions,
                  you can contact us:
                </p>
                <ul>
                  <li>
                    <p>By email: contact@msxp.ca</p>
                  </li>
                  <li>
                    <p>
                      By visiting this page on our website:{" "}
                      <a
                        href="https://msxp.ca/contact"
                        rel="external nofollow noopener"
                        target="_blank"
                      >
                        https://msxp.ca/contact
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>By phone: +1 418 476 9653</p>
                  </li>
                </ul>
              </Contract.Document>
            </Contract.Documents>
          </Contract>
        </Container>
      </div>
    </>
  );
};

export default TermsOfService;