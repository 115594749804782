import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Lang } from "../language/LanguageProvider";

import Logo from "./Logo";

const Footer = () => {
  return (
    <footer>
      <div className="footer-main pt-5 pb-2">
        <Container>
          <Row className="g-4">
            <Col md={4}>
              <div className="d-flex flex-column gap-3">
                <Link to="/" className="logo">
                  <Logo />
                </Link>
                <p className="m-0">{Lang("footerDescription")}</p>
                <div className="info-list d-flex flex-column gap-2">
                  <div className="info d-flex align-items-center gap-1">
                    <strong>{Lang("emailLabel")}:</strong>
                    <span>contact@msxp.ca</span>
                  </div>
                  <div className="info d-flex align-items-center gap-1">
                    <strong>{Lang("phoneLabel")}:</strong>
                    <span>+1 418 476 9653</span>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <Row className="g-1 gy-3 ms-md-5 ps-md-5">
                <Col sm={6}>
                  <div className="d-flex flex-column">
                    <span className="font-size-xl font-medium mb-3">
                      {Lang("services")}
                    </span>
                    <ul className="list-unstyled d-flex flex-column gap-3">
                      <li>
                        <Link
                          className="text-color dominant-hover-color"
                          to="/cybersecurity"
                        >
                          {Lang("cybersecurity")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-color dominant-hover-color"
                          to="/infrastructure-and-networks"
                        >
                          {Lang("infrastructureAndNetworks")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-color dominant-hover-color"
                          to="/digital-transformation"
                        >
                          {Lang("digitalTransformation")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-color dominant-hover-color"
                          to="/it-consulting"
                        >
                          {Lang("itConsulting")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-color dominant-hover-color"
                          to="/software-management"
                        >
                          {Lang("softwareManagement")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-color dominant-hover-color"
                          to="/development"
                        >
                          {Lang("development")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="d-flex flex-column">
                    <span className="font-size-xl font-medium mb-3">
                      {Lang("msxpConnect")}
                    </span>
                    <ul className="list-unstyled d-flex flex-column gap-3">
                      <li>
                        <Link
                          className="text-color dominant-hover-color"
                          to="/about-us"
                        >
                          {Lang("aboutUs")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-color dominant-hover-color"
                          to="/contact-us"
                        >
                          {Lang("contactUs")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-color dominant-hover-color"
                          to="/terms-of-service"
                        >
                          {Lang("termsOfService")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-color dominant-hover-color"
                          to="/terms-of-privacy"
                        >
                          {Lang("termsOfPrivacy")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-copyright pt-4 pb-4">
        <Container>
          <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-2">
            <span className="me-auto">{Lang("copyrightText")}</span>
            <span>{Lang("headquartersText")}</span>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;