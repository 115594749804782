import React from "react";
import { useState, useEffect, createContext, useContext } from "react";
import { Row, Col } from "react-bootstrap";

const ContractContext = createContext([]);

export const useContract = () => useContext(ContractContext);

const Contract = ({ children }) => {
  const [activeHash, setActiveHash] = useState(window.location.hash || "");

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash || "";
      setActiveHash(hash);
      if (hash) {
        const targetElement = document.querySelector(hash);
        if (targetElement) {
          const offsetTop = targetElement.offsetTop;
          window.scrollTo(0, offsetTop);
        }
      }
    };

    window.addEventListener("hashchange", handleHashChange);

    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <ContractContext.Provider value={{ activeHash, setActiveHash }}>
      <Row className="row contract g-4">{children}</Row>
    </ContractContext.Provider>
  );
};

const Links = ({ children, title }) => {
  return (
    <Col lg={4}>
      <div className="contract-sidebar d-flex flex-column me-0 me-lg-4">
        <span className="font-size-xl font-medium mb-3">{title}</span>
        <nav>
          <ul className="list-unstyled d-flex flex-column gap-3">
            <Link target="all">Show All</Link>
            {children}
          </ul>
        </nav>
      </div>
    </Col>
  );
};

const Link = ({ children, target, ...props }) => {
  const { activeHash, setActiveHash } = useContract();

  const handleClick = (event, hash) => {
    event.preventDefault();
    window.location.hash = `#${hash}`;
    setActiveHash(`#${hash}`);
  };

  return (
    <li>
      <a
        href={`#${target}`}
        className={`text-color dominant-hover-color ${
          activeHash === `#${target}` ? "active" : ""
        }`}
        onClick={(e) => handleClick(e, target)}
        {...props}
      >
        {children}
      </a>
    </li>
  );
};

const Documents = ({ children }) => {
  return (
    <Col lg={8}>
      <div className="contract-document d-flex flex-column">{children}</div>
    </Col>
  );
};

const Document = ({ children, id }) => {
  const { activeHash } = useContract();

  return (
    <div
      id={id}
      className={
        activeHash === `#${id}` || activeHash === "#all" || activeHash === ""
          ? "d-block"
          : "d-none"
      }
    >
      {children}
    </div>
  );
};

Contract.Links = Links;
Contract.Link = Link;
Contract.Documents = Documents;
Contract.Document = Document;

export default Contract;