import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import LucideIcon from "../components/common/LucideIcon";
import { Lang } from "../components/language/LanguageProvider";
import AdvantagesSection from "../components/common/AdvantagesSection";

// Assets
import Logo from "../components/common/Logo";
import superVistor from "../assets/img/design/supervisor.jpg";

const InfrastructureAndNetworks = () => {
  return (
    <>
      <div className="thumb p-0">
        <div className="section position-relative">
          <div className="bg-poly">
            <Container>
              <div className="row g-4">
                <div className="col-md-6" data-aos="fade-right">
                  <div className="bg-decoration d-flex align-items-center justify-content-center pt-0 pt-sm-5">
                    <div className="image-wrapper lg rounded-1 mt-4 border">
                      <img src={superVistor} alt={Lang("imageAltText")} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" data-aos="fade-left">
                  <div className="d-flex flex-column align-items-start gap-3">
                    <div className="label">
                      <h1 className="h4">{Lang("itSolutionsTitle")}</h1>
                      <p className="h6 font-regular">
                        {Lang("itSolutionsDescription")}
                      </p>
                    </div>
                    <Link to="#details">
                      <Button className="btn-lg btn-primary">
                        {Lang("learnMoreButton")}
                      </Button>
                    </Link>
                    <ul className="list-unstyled">
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="bg-white rounded-5 text-success"
                          />
                          <span className="me-auto">
                            {Lang("highAvailability")}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="bg-white rounded-5 text-success"
                          />
                          <span>{Lang("scalability")}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="bg-white rounded-5 text-success"
                          />
                          <span>{Lang("integratedSecurity")}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="bg-white rounded-5 text-success"
                          />
                          <span>{Lang("monitoring")}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="bg-white rounded-5 text-success"
                          />
                          <span>{Lang("dedicatedSupport")}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <section className="bg-secondary-tc">
        <Container>
          <div className="text d-flex flex-column align-items-center text-center mb-4">
            <h2 className="h2">{Lang("chooseMSXPTitle")}</h2>
          </div>
          <Row xs={1} md={2} lg={3} className="boxes g-4">
            <Col data-aos="zoom-in">
              <div className="box bg-primary-tc flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("dataCenterManagementTitle")}
                  </span>
                  <span className="d-block font-size-lg mb-1">
                    {Lang("dataCenterManagementDesc")}
                  </span>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          strokeWidth={2}
                          size={18}
                          className="text-success"
                        />
                        <span className="me-auto">
                          {Lang("constantMonitoring")}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          strokeWidth={2}
                          size={18}
                          className="text-success"
                        />
                        <span>{Lang("backupSystems")}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          strokeWidth={2}
                          size={18}
                          className="text-success"
                        />
                        <span>{Lang("energyReduction")}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          strokeWidth={2}
                          size={18}
                          className="text-success"
                        />
                        <span>{Lang("resourceAddition")}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in">
              <div className="box bg-primary-tc flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("corporateNetworksTitle")}
                  </span>
                  <span className="d-block font-size-lg mb-1">
                    {Lang("corporateNetworksDesc")}
                  </span>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          strokeWidth={2}
                          size={18}
                          className="text-success"
                        />
                        <span className="me-auto">
                          {Lang("effectiveProtection")}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          strokeWidth={2}
                          size={18}
                          className="text-success"
                        />
                        <span>{Lang("failoverSystems")}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          strokeWidth={2}
                          size={18}
                          className="text-success"
                        />
                        <span>{Lang("globalIntegration")}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          strokeWidth={2}
                          size={18}
                          className="text-success"
                        />
                        <span>{Lang("centralizedAdmin")}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in">
              <div className="box bg-primary-tc flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("internetProvidersTitle")}
                  </span>
                  <span className="d-block font-size-lg mb-1">
                    {Lang("internetProvidersDesc")}
                  </span>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          strokeWidth={2}
                          size={18}
                          className="text-success"
                        />
                        <span className="me-auto">
                          {Lang("highPerformance")}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          strokeWidth={2}
                          size={18}
                          className="text-success"
                        />
                        <span>{Lang("qualifiedAssistance")}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          strokeWidth={2}
                          size={18}
                          className="text-success"
                        />
                        <span>{Lang("proactiveMaintenance")}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          strokeWidth={2}
                          size={18}
                          className="text-success"
                        />
                        <span>{Lang("continuousAnalysis")}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <div className="section position-relative">
          <div className="bg-servers">
            <Container>
              <div
                className="d-flex flex-column align-items-start gap-3"
                data-aos="zoom-in"
              >
                <div className="label">
                  <h3 className="h4 mb-3">
                    {Lang("highPerformanceInfrastructureTitle")}
                  </h3>
                  <p className="font-size-xxl font-regular">
                    {Lang("highPerformanceInfrastructureDesc")}
                  </p>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </section>
      <section>
        <Container>
          <div className="d-flex flex-column align-items-center gap-2">
            <div className="section-label">
              <h4 className="h2">{Lang("securityServicesTitle")}</h4>
              <p>{Lang("securityServicesDesc")}</p>
            </div>
            <Row xs={1} md={2} lg={3} className="boxes g-4">
              <Col data-aos="flip-up">
                <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                  <LucideIcon
                    name="Server"
                    strokeWidth={1.4}
                    width={26}
                    height={26}
                  />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("dataCenterManagementTitle")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("dataCenterManagementDesc")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="flip-up">
                <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                  <LucideIcon
                    name="Globe"
                    strokeWidth={1.4}
                    width={26}
                    height={26}
                  />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("networkOptimizationTitle")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("networkOptimizationDesc")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="flip-up">
                <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                  <LucideIcon
                    name="Headphones"
                    strokeWidth={1.4}
                    width={26}
                    height={26}
                  />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("specializedSupportTitle")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("specializedSupportDesc")}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="bg-secondary-tc">
        <div className="container">
          <div
            className="d-flex flex-column align-items-center gap-2"
            data-aos="zoom-in"
          >
            <div className="logo lg">
              <Logo />
            </div>
            <div className="section-label mb-0">
              <h5 className="h2">{Lang("transformITInfrastructureTitle")}</h5>
              <p>{Lang("transformITInfrastructureDesc")}</p>
            </div>
            <Link to="/contact-us" className="btn btn-lg btn-primary rounded-5">
              {Lang("consultButton")}
            </Link>
          </div>
        </div>
      </section>
      <AdvantagesSection className="pb-4" />
    </>
  );
};

export default InfrastructureAndNetworks;