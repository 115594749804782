import { React, useState } from "react";
import { Navbar, Nav, NavDropdown, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import { Lang } from "../language/LanguageProvider";
import LucideIcon from "./LucideIcon";
import LanguageDropdown from "./LanguageDropdown";

const Header = () => {
  const [navbarStatus, setNavbarStatus] = useState(false);
  return (
    <header>
      <div className="header-main">
        <Container>
          <div className="content d-flex align-items-center justify-content-between gap-2">
            <div className="w-25">
              <Link to="/" className="logo me-auto">
                <Logo />
              </Link>
            </div>
            <Button
              variant
              className="navbar-toggler"
              onClick={() => setNavbarStatus(!navbarStatus)}
            >
              <LucideIcon
                name="AlignJustify"
                strokeWidth={1.8}
                size={26}
                className="lucide"
              />
            </Button>
            <Navbar
              expand="lg"
              className={[
                "flex-grow-1 w-75 align-items-start justify-content-start flex-row-reverse",
                navbarStatus ? "show" : "",
              ].join(" ")}
              expanded={navbarStatus}
            >
              <Navbar.Collapse
                id="navbarHeaderMain"
                className="justify-content-center"
              >
                <Nav className="navbar-nav flex-fill justify-content-center">
                  <Nav.Item>
                    <Nav.Link as={Link} to="/" className="active">
                      {Lang("home")}
                    </Nav.Link>
                  </Nav.Item>
                  <NavDropdown title={Lang("services")} id="services-dropdown">
                    <NavDropdown.Item as={Link} to="/cybersecurity">
                      {Lang("cybersecurity")}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/infrastructure-and-networks"
                    >
                      {Lang("infrastructureAndNetworks")}
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/digital-transformation">
                      {Lang("digitalTransformation")}
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/it-consulting">
                      {Lang("itConsulting")}
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/software-management">
                      {Lang("softwareManagement")}
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/development">
                      {Lang("development")}
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title={Lang("msxpConnect")} id="msxp-dropdown">
                    <NavDropdown.Item as={Link} to="/about-us">
                      {Lang("aboutUs")}
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/contact-us">
                      {Lang("contactUs")}
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/terms-of-service">
                      {Lang("termsOfService")}
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/terms-of-privacy">
                      {Lang("termsOfPrivacy")}
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <Nav className="navbar-nav flex-fill justify-content-end">
                  <LanguageDropdown />
                  <Nav.Item>
                    <Nav.Link as={Link} to="/">
                      {Lang("loginToAccount")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;