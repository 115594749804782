import React from "react";
import { Carousel, Container } from "react-bootstrap";
import LucideIcon from "../../components/common/LucideIcon";
import { Lang } from "../../components/language/LanguageProvider";

const CarouselServices = ({ ...props }) => {
  return (
    <section
      aria-label={Lang("carousel_section_title")}
      className="overflow-auto"
    >
      <Carousel {...props} id="services">
        <Carousel.Item>
          <Container className="container">
            <div className="bg-secondary-tc p-4 p-sm-5 rounded-1 d-flex flex-column align-items-start gap-3">
              <div className="label">
                <span className="h4">{Lang("software_development_title")}</span>
                <p className="h6 font-regular">
                  {Lang("software_development_description")}
                </p>
              </div>
              <a href="/development" className="btn btn-lg btn-primary">
                {Lang("discover_solutions")}
              </a>
              <ul className="list-unstyled mb-0">
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span className="me-auto">
                      <strong>{Lang("development")}</strong>{" "}
                      {Lang("development_description")}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span>
                      <strong>{Lang("lifecycle_management")}</strong>{" "}
                      {Lang("lifecycle_management_description")}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span>
                      <strong>{Lang("scalability")}</strong>{" "}
                      {Lang("scalability_description")}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span>
                      <strong>{Lang("technical_support")}</strong>{" "}
                      {Lang("technical_support_description")}
                    </span>
                  </div>
                </li>
              </ul>
              <p>
                {Lang("contact_us_question")}{" "}
                <a href="/contact-us">{Lang("contact_us")}</a>
              </p>
            </div>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container className="container">
            <div className="bg-secondary-tc p-4 p-sm-5 rounded-1 d-flex flex-column align-items-start gap-3">
              <div className="label">
                <span className="h4">{Lang("cybersecurity_title")}</span>
                <p className="h6 font-regular">
                  {Lang("cybersecurity_description")}
                </p>
              </div>
              <a href="/cybersecurity" className="btn btn-lg btn-primary">
                {Lang("learn_more_solutions")}
              </a>
              <ul className="list-unstyled mb-0">
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span className="me-auto">
                      <strong>{Lang("data_protection")}</strong>{" "}
                      {Lang("data_protection_description")}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span>
                      <strong>{Lang("continuous_monitoring")}</strong>{" "}
                      {Lang("continuous_monitoring_description")}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span>
                      <strong>{Lang("compliance_audit")}</strong>{" "}
                      {Lang("compliance_audit_description")}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span>
                      <strong>{Lang("incident_response")}</strong>{" "}
                      {Lang("incident_response_description")}
                    </span>
                  </div>
                </li>
              </ul>
              <p>
                {Lang("contact_us_question")}{" "}
                <a href="/contact-us">{Lang("contact_us")}</a>
              </p>
            </div>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container className="container">
            <div className="bg-secondary-tc p-4 p-sm-5 rounded-1 d-flex flex-column align-items-start gap-3">
              <div className="label">
                <span className="h4">
                  {Lang("digital_transformation_title")}
                </span>
                <p className="h6 font-regular">
                  {Lang("digital_transformation_description")}
                </p>
              </div>
              <a
                href="/digital-transformation"
                className="btn btn-lg btn-primary"
              >
                {Lang("explore_solutions")}
              </a>
              <ul className="list-unstyled mb-0">
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span className="me-auto">
                      <strong>{Lang("website_creation")}</strong>{" "}
                      {Lang("website_creation_description")}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span>
                      <strong>{Lang("icon_design")}</strong>{" "}
                      {Lang("icon_design_description")}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span>
                      <strong>{Lang("banners_elements")}</strong>{" "}
                      {Lang("banners_elements_description")}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span>
                      <strong>{Lang("seo_optimization")}</strong>{" "}
                      {Lang("seo_optimization_description")}
                    </span>
                  </div>
                </li>
              </ul>
              <p>
                {Lang("digital_transformation_question")}{" "}
                <a href="/contact-us">{Lang("contact_us")}</a>
              </p>
            </div>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container className="container">
            <div className="bg-secondary-tc p-4 p-sm-5 rounded-1 d-flex flex-column align-items-start gap-3">
              <div className="label">
                <span className="h4">{Lang("it_consulting_title")}</span>
                <p className="h6 font-regular">
                  {Lang("it_consulting_description")}
                </p>
              </div>
              <a href="/it-consulting" className="btn btn-lg btn-primary">
                {Lang("learn_more_consulting")}
              </a>
              <ul className="list-unstyled mb-0">
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span className="me-auto">
                      <strong>{Lang("analysis_diagnosis")}</strong>{" "}
                      {Lang("analysis_diagnosis_description")}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span>
                      <strong>{Lang("strategic_planning")}</strong>{" "}
                      {Lang("strategic_planning_description")}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span>
                      <strong>{Lang("solution_implementation")}</strong>{" "}
                      {Lang("solution_implementation_description")}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                    <LucideIcon
                      name="Check"
                      strokeWidth={2}
                      size={18}
                      className="text-success"
                    />
                    <span>
                      <strong>{Lang("support_monitoring")}</strong>{" "}
                      {Lang("support_monitoring_description")}
                    </span>
                  </div>
                </li>
              </ul>
              <p>
                {Lang("consulting_question")}{" "}
                <a href="/contact-us">{Lang("contact_us")}</a>
              </p>
            </div>
          </Container>
        </Carousel.Item>
      </Carousel>
    </section>
  );
};

export default CarouselServices;