import React from "react";
import { Container, Row, Col, } from "react-bootstrap";
import LucideIcon from "../components/common/LucideIcon";
import { Lang } from "../components/language/LanguageProvider";
import CarouselServices from "../components/common/CarouselServices";
import AdvantagesSection from "../components/common/AdvantagesSection";

// Assets
import team from "../assets/img/design/team.jpg";

const AboutUs = () => {
  return (
    <>
      <div className="thumb bg-secondary-tc pt-2">
        <Container>
          <div className="section-label d-flex flex-column justify-content-center align-items-center mb-4">
            <span
              className="badge rounded-pill text-bg-color mb-3"
              data-aos="fade-down"
            >
              {Lang("aboutOurCompany")}
            </span>
            <div className="h1">{Lang("welcomeMessage")}</div>
          </div>
        </Container>
      </div>
      <section>
        <Container>
          <Row className="g-4">
            <Col md={12} data-aos="fade-right">
              <div className="d-flex align-items-center">
                <span className="font-size-xxl text-muted">
                  {Lang("ourHistory")}
                </span>
              </div>
            </Col>
            <Col md={12} data-aos="fade-left">
              <div className="d-flex align-items-center">
                <h1 className="h2 me-auto">{Lang("getToKnowUs")}</h1>
                <a
                  href="#services"
                  className="btn-arrow d-flex align-items-center gap-2"
                >
                  <span>{Lang("exploreOurServices")}</span>
                  <LucideIcon
                    name="ArrowRight"
                    strokeWidth={2}
                    size={18}
                  />
                </a>
              </div>
            </Col>
            <Col md={6} data-aos="fade-right">
              <div className="bg-decoration d-flex align-items-center justify-content-center pt-5">
                <div className="image-wrapper lg rounded-1 mt-4">
                  <img src={team} alt={Lang("teamImageAlt")} />
                </div>
              </div>
            </Col>
            <Col md={6} data-aos="fade-left">
              <div className="d-flex flex-column align-items-start gap-3">
                <div className="label">
                  <span className="h4">{Lang("ourExpertiseTitle")}</span>
                  <p>{Lang("ourExpertiseDescription")}</p>
                </div>
                <div className="label">
                  <span className="h4">{Lang("ourCommitmentTitle")}</span>
                  <p>{Lang("ourCommitmentDescription")}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="p-0">
        <div className="section bg-gray-light position-relative">
          <div className="bg-waves pt-5 pb-5">
            <Container>
              <div className="d-flex flex-column align-items-center gap-2">
                <div className="section-label">
                  <h2 className="h2">{Lang("missionValuesVision")}</h2>
                </div>
                <Row xs={1} md={2} lg={4} className="boxes g-4">
                  <Col data-aos="flip-left">
                    <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                      <LucideIcon
                        name="Lightbulb"
                        strokeWidth={1.4}
                        size={26}
                      />
                      <div className="d-flex flex-column gap-1 mt-1 mb-1">
                        <span className="d-block font-medium font-size-xl me-auto">
                          {Lang("innovationTitle")}
                        </span>
                        <span className="d-block font-size-lg">
                          {Lang("innovationDescription")}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col data-aos="flip-left">
                    <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                      <LucideIcon
                        name="Award"
                        strokeWidth={1.4}
                        size={26}
                      />
                      <div className="d-flex flex-column gap-1 mt-1 mb-1">
                        <span className="d-block font-medium font-size-xl me-auto">
                          {Lang("commitmentTitle")}
                        </span>
                        <span className="d-block font-size-lg">
                          {Lang("commitmentDescription")}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col data-aos="flip-right">
                    <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                      <LucideIcon
                        name="Heart"
                        strokeWidth={1.4}
                        size={26}
                      />
                      <div className="d-flex flex-column gap-1 mt-1 mb-1">
                        <span className="d-block font-medium font-size-xl me-auto">
                          {Lang("customerFocusTitle")}
                        </span>
                        <span className="d-block font-size-lg">
                          {Lang("customerFocusDescription")}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col data-aos="flip-right">
                    <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                      <LucideIcon
                        name="Users"
                        strokeWidth={1.4}
                        size={26}
                      />
                      <div className="d-flex flex-column gap-1 mt-1 mb-1">
                        <span className="d-block font-medium font-size-xl me-auto">
                          {Lang("dedicatedTeamTitle")}
                        </span>
                        <span className="d-block font-size-lg">
                          {Lang("dedicatedTeamDescription")}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </section>
      <CarouselServices />
      <AdvantagesSection className="pb-4" />
    </>
  );
};

export default AboutUs;