import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import LucideIcon from "../components/common/LucideIcon";
import { Lang } from "../components/language/LanguageProvider";
import Logo from "../components/common/Logo";
import AdvantagesSection from "../components/common/AdvantagesSection";

const ItConsulting = () => {
  return (
    <>
      <div className="thumb bg-secondary-tc pt-2">
        <Container>
          <div className="section-label d-flex flex-column justify-content-center align-items-center">
            <div
              className="badge rounded-pill text-bg-color mb-3"
              data-aos="fade-down"
            >
              {Lang("itConsulting")}
            </div>
            <h1 className="h1">
              {Lang("transformChallengesIntoOpportunities")}
            </h1>
            <p>{Lang("itConsultingDescription")}</p>
          </div>
        </Container>
      </div>
      <section>
        <Container>
          <div className="section-label">
            <h2 className="h2">{Lang("ourITConsultingServices")}</h2>
            <p>{Lang("exploreOurRangeOfITServices")}</p>
          </div>
          <Row xs={1} md={2} lg={4} className="boxes g-4">
            <Col data-aos="zoom-in-right">
              <div className="box bg-secondary-tc border rounded-1 flex-column p-4 gap-2">
                <LucideIcon name="Server" strokeWidth={1.4} size={26} />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("infrastructureAnalysis")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("evaluateYourCurrentInfrastructure")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in-right">
              <div className="box bg-secondary-tc border rounded-1 flex-column p-4 gap-2">
                <LucideIcon
                  name="ChartNoAxesCombined"
                  strokeWidth={1.4}
                  size={26}
                />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("strategicPlanning")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("developTechPlansAlignedWithBusiness")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in-left">
              <div className="box bg-secondary-tc border rounded-1 flex-column p-4 gap-2">
                <LucideIcon name="Calendar" strokeWidth={1.4} size={26} />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("projectManagement")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("manageITProjectsEfficiently")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in-left">
              <div className="box bg-secondary-tc border rounded-1 flex-column p-4 gap-2">
                <LucideIcon name="Lock" strokeWidth={1.4} size={26} />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("informationSecurity")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("implementSecurityMeasures")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in-right">
              <div className="box bg-secondary-tc border rounded-1 flex-column p-4 gap-2">
                <LucideIcon name="Cog" strokeWidth={1.4} size={26} />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("processOptimization")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("improveEfficiencyWithOptimizedProcesses")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in-right">
              <div className="box bg-secondary-tc border rounded-1 flex-column p-4 gap-2">
                <LucideIcon name="GraduationCap" strokeWidth={1.4} size={26} />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("teamTraining")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("empowerYourTeamWithTraining")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in-left">
              <div className="box bg-secondary-tc border rounded-1 flex-column p-4 gap-2">
                <LucideIcon name="Cloud" strokeWidth={1.4} size={26} />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("cloudConsulting")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("adoptCloudSolutionsForFlexibility")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in-left">
              <div className="box bg-secondary-tc border rounded-1 flex-column p-4 gap-2">
                <LucideIcon name="Wrench" strokeWidth={1.4} size={26} />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("technicalSupport")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("getOngoingTechnicalSupport")}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-secondary-tc">
        <Container>
          <div className="d-flex flex-column align-items-start gap-3">
            <div className="label" data-aos="fade-right">
              <h3 className="h4">{Lang("maximizeITPotential")}</h3>
              <p className="h6 font-regular">
                {Lang("comprehensivePersonalizedSolutions")}
              </p>
            </div>
            <ul className="list-unstyled" data-aos="fade-left">
              <li>
                <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                  <LucideIcon
                    name="Check"
                    strokeWidth={2}
                    size={18}
                    className="text-success"
                  />
                  <span className="me-auto">
                    {Lang("detailedInfrastructureAssessment")}
                  </span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                  <LucideIcon
                    name="Check"
                    strokeWidth={2}
                    size={18}
                    className="text-success"
                  />
                  <span>{Lang("technologyPlansAlignedWithGoals")}</span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                  <LucideIcon
                    name="Check"
                    strokeWidth={2}
                    size={18}
                    className="text-success"
                  />
                  <span>{Lang("coordinationAndExecutionOfProjects")}</span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                  <LucideIcon
                    name="Check"
                    strokeWidth={2}
                    size={18}
                    className="text-success"
                  />
                  <span>{Lang("robustSecurityMeasures")}</span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                  <LucideIcon
                    name="Check"
                    strokeWidth={2}
                    size={18}
                    className="text-success"
                  />
                  <span>{Lang("operationalEfficiencyImprovements")}</span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                  <LucideIcon
                    name="Check"
                    strokeWidth={2}
                    size={18}
                    className="text-success"
                  />
                  <span>{Lang("personalizedTeamTraining")}</span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                  <LucideIcon
                    name="Check"
                    strokeWidth={2}
                    size={18}
                    className="text-success"
                  />
                  <span>{Lang("cloudAdoptionAndManagement")}</span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                  <LucideIcon
                    name="Check"
                    strokeWidth={2}
                    size={18}
                    className="text-success"
                  />
                  <span>{Lang("rapidEffectiveTechnicalSupport")}</span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                  <LucideIcon
                    name="Check"
                    strokeWidth={2}
                    size={18}
                    className="text-success"
                  />
                  <span>{Lang("systemsIntegration")}</span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                  <LucideIcon
                    name="Check"
                    strokeWidth={2}
                    size={18}
                    className="text-success"
                  />
                  <span>{Lang("detailedReportingAndAnalysis")}</span>
                </div>
              </li>
            </ul>
          </div>
        </Container>
      </section>
      <section>
        <Container>
          <Row className="g-4 mb-4">
            <Col md={12} data-aos="fade-right">
              <div className="d-flex align-items-center">
                <span className="font-size-xxl text-muted">
                  {Lang("itConsulting")}
                </span>
              </div>
            </Col>
            <Col md={12} data-aos="fade-left">
              <div className="d-flex align-items-center gap-5">
                <h4 className="h2 me-auto" data-aos="fade-right">
                  {Lang("consultingSolutionsForEfficientFuture")}
                </h4>
                <span data-aos="fade-left">
                  {Lang("transformHowYourCompanyUsesTechnology")}
                </span>
              </div>
            </Col>
          </Row>
          <Row xs={1} md={2} lg={3} className="boxes g-4">
            <Col data-aos="zoom-in">
              <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                <LucideIcon name="Server" strokeWidth={1.4} size={26} />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("evaluationAndPlanning")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("completeAnalysisAndStrategicPlan")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in">
              <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                <LucideIcon name="Globe" strokeWidth={1.4} size={26} />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("implementationAndManagement")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("manageTechSolutionsAndContinuousMonitoring")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in">
              <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                <LucideIcon name="Headphones" strokeWidth={1.4} size={26} />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("supportAndTraining")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("dedicatedTechSupportAndProactiveMaintenance")}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-secondary-tc">
        <div className="container">
          <div
            className="d-flex flex-column align-items-center gap-2"
            data-aos="zoom-in"
          >
            <div className="logo lg">
              <Logo />
            </div>
            <div className="section-label mb-0">
              <h5 className="h2">{Lang("readyToTransformTechnology")}</h5>
              <p>{Lang("digitalTransformationKeyToInnovation")}</p>
            </div>
            <Link to="/contact-us" className="btn btn-lg btn-primary rounded-5">
              {Lang("requestFreeConsulting")}
            </Link>
          </div>
        </div>
      </section>
      <AdvantagesSection className="pb-4" />
    </>
  );
};

export default ItConsulting;