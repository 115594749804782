import * as React from "react"
const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 153 173.639"
    {...props}
  >
    <g
      style={{
        strokeWidth: 0.669366,
      }}
    >
      <g
        style={{
          fill: "#000",
          strokeWidth: 0,
          strokeDasharray: "none",
        }}
      >
        <path
          d="M217.235-82.556a16.94 16.942 0 0 0-6.977 1.504C124.796-42.444 86.565-30.564-5.017-14.129A16.94 16.942 0 0 0-18.94 1.479c-4.076 64.71 4.615 125.004 25.864 179.21a370 370.055 0 0 0 75.85 119.137c56.61 60.071 116.717 86.162 128.183 90.779a16.94 16.942 0 0 0 12.704 0c11.466-4.617 71.573-30.708 128.184-90.78a370 370.055 0 0 0 75.7-119.137c21.25-54.205 29.94-114.5 25.864-179.209a16.94 16.942 0 0 0-13.922-15.608c-91.582-16.435-129.813-28.315-215.275-66.923a16.94 16.942 0 0 0-6.977-1.504z"
          style={{
            fill: "#374f56",
            fillOpacity: 1,
            stroke: "#fff",
            strokeWidth: 0,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="matrix(.32258 0 0 .36602 6.425 30.217)"
        />
      </g>
      <g
        style={{
          fill: "#000",
          strokeWidth: 4.99651,
          strokeDasharray: "none",
        }}
      >
        <g
          style={{
            strokeWidth: 4.99651,
          }}
        >
          <path
            d="M146.992 404.835a16 16 0 0 0 3.06-.596c10.83-4.36 67.603-29 121.073-85.73A349.47 349.47 0 0 0 342.623 206c20.07-51.19 28.28-108.13 24.43-169.24A16 16 0 0 0 353.9 22.02c-86.5-15.52-122.61-26.74-203.33-63.2a16 16 0 0 0-3.579-.771z"
            style={{
              color: "#000",
              fill: "#1e2b2f",
              InkscapeStroke: "none",
              fillOpacity: 1,
            }}
            transform="matrix(.30306 0 0 .34388 32.864 24.429)"
          />
          <path
            d="M142.791-46.586v456.041l4.6-.437a20.194 20.194 0 0 0 3.863-.752l.187-.055.18-.074c11.198-4.508 68.485-29.375 122.555-86.739l.006-.006a353.676 353.676 0 0 0 72.353-113.857v-.002C366.85 155.72 375.13 98.142 371.246 36.494c-.586-9.265-7.465-16.976-16.603-18.611h-.002C268.289 2.389 232.886-8.611 152.3-45.01l-.186-.086-.195-.064a20.189 20.189 0 0 0-4.518-.975zm8.404 10.275C230.321-.679 267.408 10.77 353.158 26.154h.004a11.76 11.76 0 0 1 9.697 10.87c3.817 60.572-4.322 116.874-24.148 167.44a345.27 345.27 0 0 1-70.639 111.157l-.002.004-.004.002c-50.579 53.663-103.156 77.757-116.87 83.48z"
            style={{
              color: "#000",
              fill: "#fff",
              InkscapeStroke: "none",
            }}
            transform="matrix(.30306 0 0 .34388 32.864 24.429)"
          />
          <path
            d="M140.974-41.952a16 16 0 0 0-3.582.77c-80.72 36.46-116.827 47.68-203.327 63.2A16 16 0 0 0-79.086 36.76c-3.85 61.11 4.36 118.05 24.429 169.24a349.47 349.47 0 0 0 71.64 112.51c53.47 56.73 110.241 81.37 121.071 85.73a16 16 0 0 0 2.92.569z"
            style={{
              color: "#000",
              fill: "#374f56",
              InkscapeStroke: "none",
              fillOpacity: 1,
            }}
            transform="matrix(.30306 0 0 .34388 32.864 24.429)"
          />
          <path
            d="m145.176-46.584-4.61.45a20.21 20.21 0 0 0-4.523.974l-.193.064-.188.086C55.076-8.61 19.676 2.39-66.678 17.883c-9.138 1.635-16.016 9.346-16.601 18.611-3.884 61.648 4.396 119.226 24.71 171.04v.001l.003.004a353.67 353.67 0 0 0 72.5 113.863c54.068 57.361 111.353 82.227 122.55 86.735l.192.076.195.059c1.204.353 2.437.592 3.686.716l4.619.461zm-8.405 10.272v435.357c-13.866-5.798-66.268-29.88-116.73-83.418l-.006-.004-.004-.006a345.26 345.26 0 0 1-70.775-111.152l-.002-.004C-70.571 153.895-78.71 97.594-74.893 37.023a11.76 11.76 0 0 1 9.698-10.869h.002C20.558 10.77 57.643-.679 136.77-36.312Z"
            style={{
              color: "#000",
              fill: "#fff",
              InkscapeStroke: "none",
            }}
            transform="matrix(.30306 0 0 .34388 32.864 24.429)"
          />
        </g>
      </g>
    </g>
    <g
      style={{
        strokeWidth: 0.653235,
      }}
    >
      <path
        d="M130.415 106.226H116.16V69.022l-10.3 24.143h-9.888l-10.3-24.143v37.204H72.164V50.662h16.643l12.5 27.875 12.465-27.875h16.643z"
        style={{
          color: "#000",
          InkscapeFontSpecification: "&quot",
          fill: "#1e2b2f",
          strokeLinejoin: "round",
          InkscapeStroke: "none",
          fillOpacity: 1,
        }}
        transform="matrix(1.36909 0 0 1.71172 -62.174 -51.507)"
      />
      <path
        d="M72.164 49.809a.854.854 0 0 0-.853.853v55.565a.854.854 0 0 0 .853.853h13.508a.854.854 0 0 0 .853-.853v-33.03L95.187 93.5a.854.854 0 0 0 .784.52h9.89a.854.854 0 0 0 .785-.52l8.66-20.303v33.03a.854.854 0 0 0 .854.853h14.254a.854.854 0 0 0 .854-.853V50.662a.854.854 0 0 0-.854-.853h-16.643a.854.854 0 0 0-.779.504l-11.685 26.136-11.721-26.136a.854.854 0 0 0-.78-.504zm.854 1.707h15.236l12.275 27.37a.854.854 0 0 0 1.559-.001l12.236-27.37h15.237v53.858h-12.547V69.021a.854.854 0 0 0-1.639-.334l-10.078 23.626h-8.762L86.457 68.686a.854.854 0 0 0-1.639.334v36.352h-11.8Z"
        style={{
          color: "#000",
          InkscapeFontSpecification: "&quot",
          fill: "#fff",
          strokeLinejoin: "round",
          InkscapeStroke: "none",
        }}
        transform="matrix(1.36909 0 0 1.71172 -62.174 -51.507)"
      />
    </g>
  </svg>
)
export default Logo;