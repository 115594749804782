import React from "react";
import { Container } from "react-bootstrap";
import LucideIcon from "../../components/common/LucideIcon";
import { Lang } from "../../components/language/LanguageProvider";

const AdvantagesSection = ({ className, ...props }) => {
  return (
    <div className={["section", "overflow-hidden", className].join(" ")} {...props}>
      <Container>
        <div className="d-flex align-items-center justify-content-start justify-content-md-center">
          <ul className="list-unstyled d-flex flex-column align-items-start justify-content-start flex-md-row align-items-md-center justify-content-md-center gap-2 gap-md-5">
            <li>
              <div
                className="d-flex align-items-center gap-2"
                data-aos="fade-right"
              >
                <LucideIcon
                  name="Check"
                  strokeWidth={2}
                  size={18}
                  className="bg-primary rounded-5 text-success"
                />
                <span className="font-size-xxl">
                  {Lang("proven_experience")}
                </span>
              </div>
            </li>
            <li>
              <div
                className="d-flex align-items-center gap-2"
                data-aos="fade-up"
              >
                <LucideIcon
                  name="Check"
                  strokeWidth={2}
                  size={18}
                  className="bg-primary rounded-5 text-success"
                />
                <span className="font-size-xxl">
                  {Lang("custom_solutions")}
                </span>
              </div>
            </li>
            <li>
              <div
                className="d-flex align-items-center gap-2"
                data-aos="fade-left"
              >
                <LucideIcon
                  name="Check"
                  strokeWidth={2}
                  size={18}
                  className="bg-primary rounded-5 text-success"
                />
                <span className="font-size-xxl">
                  {Lang("dedicated_support")}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </Container>
    </div>
  );
};

export default AdvantagesSection;