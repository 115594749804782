import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import LucideIcon from "../components/common/LucideIcon";
import { Lang } from "../components/language/LanguageProvider";
import Logo from "../components/common/Logo";
import AdvantagesSection from "../components/common/AdvantagesSection";

import teamTable from "../assets/img/design/team-table.jpg";
import tech01 from "../assets/img/design/tech-01.jpg";
import tech02 from "../assets/img/design/tech-02.jpg";

const SoftwareManagement = () => {
  return (
    <>
      <div className="thumb bg-secondary-tc pt-2 pb-0">
        <Container>
          <div className="section-label d-flex flex-column justify-content-center align-items-center mb-4">
            <span
              className="badge rounded-pill text-bg-color mb-3"
              data-aos="fade-down"
            >
              {Lang("softwareManagement")}
            </span>
            <h1 className="h1">{Lang("optimizeAndManageSoftwareResources")}</h1>
            <p>{Lang("msxpConnectSoftwareSolutions")}</p>
          </div>
        </Container>
        <div className="bg-secondary-tc-half-column overflow-hidden">
          <Container>
            <Row xs={1} md={2} lg={3} className="boxes g-4">
              <Col data-aos="fade-right">
                <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                  <LucideIcon name="Settings" strokeWidth={1.4} size={26} />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("softwareImplementation")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("softwareImplementationDescription")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="fade-up">
                <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                  <LucideIcon
                    name="SquareArrowUp"
                    strokeWidth={1.4}
                    size={26}
                  />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("licenseManagement")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("licenseManagementDescription")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="fade-left">
                <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                  <LucideIcon name="Wrench" strokeWidth={1.4} size={26} />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("maintenanceAndSupport")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("maintenanceAndSupportDescription")}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="section">
        <Container>
          <div className="row gy-4 flex-column-reverse flex-md-row mb-5">
            <Col md={5} data-aos="fade-right">
              <div className="d-flex flex-column align-items-start me-0 me-md-5">
                <div className="image-wrapper xl">
                  <img src={teamTable} alt={Lang("teamTableAlt")} />
                </div>
              </div>
            </Col>
            <Col md={7} data-aos="fade-left">
              <div className="d-flex flex-column">
                <span className="font-medium font-size-xxl mb-2">
                  {Lang("perfectChoiceAndIntegrity")}
                </span>
                <span className="h4 mb-3">
                  {Lang("findAndImplementBestSolutions")}
                </span>
                <p>{Lang("ourSoftwareSelectionProcess")}</p>
                <p>{Lang("afterSelectionManagement")}</p>
              </div>
            </Col>
          </div>
          <div className="row gy-4 mb-5">
            <Col md={7} data-aos="fade-right">
              <div className="d-flex flex-column me-0 me-md-5">
                <span className="font-medium font-size-xxl mb-2">
                  {Lang("complianceAndSavings")}
                </span>
                <span className="h4 mb-3">
                  {Lang("efficientLicenseManagement")}
                </span>
                <p>{Lang("manageAndMonitorLicenses")}</p>
                <p>{Lang("continuousLicenseAdjustment")}</p>
              </div>
            </Col>
            <Col md={5} data-aos="fade-left">
              <div className="d-flex flex-column align-items-start align-items-md-end">
                <div className="image-wrapper xl">
                  <img src={tech02} alt={Lang("techAlt")} />
                </div>
              </div>
            </Col>
          </div>
          <div className="row gy-4 flex-column-reverse flex-md-row">
            <Col md={5} data-aos="fade-right">
              <div className="d-flex flex-column align-items-start me-0 me-md-5">
                <div className="image-wrapper xl">
                  <img src={tech01} alt={Lang("techTableAlt")} />
                </div>
              </div>
            </Col>
            <Col md={7} data-aos="fade-left">
              <div className="d-flex flex-column">
                <span className="font-medium font-size-xxl mb-2">
                  {Lang("specializedMaintenanceAndSupport")}
                </span>
                <span className="h4 mb-3">
                  {Lang("proactiveMaintenanceAndContinuousSupport")}
                </span>
                <p>{Lang("continuousTechnicalSupport")}</p>
                <p>{Lang("regularMaintenance")}</p>
              </div>
            </Col>
          </div>
        </Container>
      </div>
      <section className="pb-0">
        <div className="waves">
          <svg
            width="1512"
            height="97"
            viewBox="0 0 1512 97"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 19.4L50.4 32.3333C100.8 45.2667 201.6 71.1333 302.4 64.6667C403.2 58.2 504 19.4 604.8 9.7C705.6 0 806.4 19.4 907.2 32.3333C1008 45.2667 1108.8 51.7333 1209.6 45.2667C1310.4 38.8 1411.2 19.4 1461.6 9.7L1512 0V97H1461.6C1411.2 97 1310.4 97 1209.6 97C1108.8 97 1008 97 907.2 97C806.4 97 705.6 97 604.8 97C504 97 403.2 97 302.4 97C201.6 97 100.8 97 50.4 97H0V19.4Z"
            ></path>
          </svg>
        </div>
        <div className="content bg-secondary-tc pt-3 pb-5">
          <Container>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="section-label">
                <h2 className="h2">
                  {Lang("softwareManagementFeaturesAndBenefits")}
                </h2>
                <p>{Lang("exploreSoftwareManagementBenefits")}</p>
              </div>
              <Row xs={1} md={2} lg={4} className="boxes g-4">
                <Col data-aos="flip-right">
                  <div className="box bg-primary-tc flex-column p-4 gap-2">
                    <div className="d-flex flex-column gap-1 mt-1 mb-1">
                      <span className="d-block font-medium font-size-xl me-auto">
                        {Lang("efficientSelection")}
                      </span>
                      <span className="d-block font-size-lg">
                        {Lang("chooseBestSolutions")}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col data-aos="flip-right">
                  <div className="box bg-primary-tc flex-column p-4 gap-2">
                    <div className="d-flex flex-column gap-1 mt-1 mb-1">
                      <span className="d-block font-medium font-size-xl me-auto">
                        {Lang("quickImplementation")}
                      </span>
                      <span className="d-block font-size-lg">
                        {Lang("integrateWithoutDisruptions")}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col data-aos="flip-left">
                  <div className="box bg-primary-tc flex-column p-4 gap-2">
                    <div className="d-flex flex-column gap-1 mt-1 mb-1">
                      <span className="d-block font-medium font-size-xl me-auto">
                        {Lang("licenseManagement")}
                      </span>
                      <span className="d-block font-size-lg">
                        {Lang("controlLicenses")}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col data-aos="flip-left">
                  <div className="box bg-primary-tc flex-column p-4 gap-2">
                    <div className="d-flex flex-column gap-1 mt-1 mb-1">
                      <span className="d-block font-medium font-size-xl me-auto">
                        {Lang("technicalSupport")}
                      </span>
                      <span className="d-block font-size-lg">
                        {Lang("ongoingAssistance")}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col data-aos="flip-right">
                  <div className="box bg-primary-tc flex-column p-4 gap-2">
                    <div className="d-flex flex-column gap-1 mt-1 mb-1">
                      <span className="d-block font-medium font-size-xl me-auto">
                        {Lang("regularUpdates")}
                      </span>
                      <span className="d-block font-size-lg">
                        {Lang("softwareUpToDate")}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col data-aos="flip-right">
                  <div className="box bg-primary-tc flex-column p-4 gap-2">
                    <div className="d-flex flex-column gap-1 mt-1 mb-1">
                      <span className="d-block font-medium font-size-xl me-auto">
                        {Lang("customTraining")}
                      </span>
                      <span className="d-block font-size-lg">
                        {Lang("teamTraining")}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col data-aos="flip-left">
                  <div className="box bg-primary-tc flex-column p-4 gap-2">
                    <div className="d-flex flex-column gap-1 mt-1 mb-1">
                      <span className="d-block font-medium font-size-xl me-auto">
                        {Lang("performanceAnalysis")}
                      </span>
                      <span className="d-block font-size-lg">
                        {Lang("continuousEvaluation")}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col data-aos="flip-left">
                  <div className="box bg-primary-tc flex-column p-4 gap-2">
                    <div className="d-flex flex-column gap-1 mt-1 mb-1">
                      <span className="d-block font-medium font-size-xl me-auto">
                        {Lang("specializedConsulting")}
                      </span>
                      <span className="d-block font-size-lg">
                        {Lang("softwareSolutionsGuidance")}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </section>
      <section>
        <Container>
          <div className="d-flex flex-column align-items-start gap-3">
            <div className="label" data-aos="zoom-in-up">
              <h3 className="h4">{Lang("transformSoftwareManagement")}</h3>
              <p className="font-size-xxl font-regular mb-4">
                {Lang("efficientSoftwareManagement")}
              </p>
              <p className="font-size-xxl font-regular">
                {Lang("adoptingOurPractices")}
              </p>
            </div>
          </div>
        </Container>
      </section>
      <section className="bg-secondary-tc">
        <div className="container">
          <div
            className="d-flex flex-column align-items-center gap-2"
            data-aos="zoom-in"
          >
            <div className="logo lg">
              <Logo />
            </div>
            <div className="section-label mb-0">
              <h5 className="h2">{Lang("revolutionizeSoftwareManagement")}</h5>
              <p>{Lang("specializedSolutions")}</p>
            </div>
            <Link to="/contact-us" className="btn btn-lg btn-primary rounded-5">
              {Lang("contactUs")}
            </Link>
          </div>
        </div>
      </section>
      <AdvantagesSection className="pb-4" />
    </>
  );
};

export default SoftwareManagement;