import React from "react";
import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import LucideIcon from "../components/common/LucideIcon";
import { Lang } from "../components/language/LanguageProvider";
import AdvantagesSection from "../components/common/AdvantagesSection";

// Assets
import designer01 from "../assets/img/design/designer-01.jpg";
import designer02 from "../assets/img/design/designer-02.jpg";
import programmer from "../assets/img/design/programmer.jpg";
import homepage from "../assets/img/design/homepage.jpg";

const DigitalTransformation = () => {
  const [activeServiceTab, setActiveServiceTab] = useState(
    "custom-websites-info"
  );
  const [activeServiceInfoTab, setActiveServiceInfoTab] = useState(
    "custom-websites-info"
  );
  return (
    <>
      <div className="thumb bg-secondary-tc pt-2 pb-0">
        <Container>
          <div className="section-label d-flex flex-column justify-content-center align-items-center mb-4">
            <span
              className="badge rounded-pill text-bg-color mb-3"
              data-aos="fade-down"
            >
              {Lang("digitalTransformationBadge")}
            </span>
            <h1 className="h1">{Lang("digitalTransformationTitle")}</h1>
            <p>{Lang("digitalTransformationDesc")}</p>
          </div>
        </Container>
        <div className="bg-secondary-tc-half-column overflow-hidden">
          <Container>
            <Row
              xs={1} md={2} lg={4}
              className="boxes boxes-toggle w-100 bg-primary-tc rounded-1 d-flex align-items-center justify-content-center m-auto nav nav-tabs card-header-tabs"
              role="tablist"
            >
              <Col className="p-0">
                <button
                  className={`box flex-column align-items-center justify-content-center text-center gap-2  ${
                    activeServiceTab === "custom-websites-info" ? "active" : ""
                  }`}
                  onClick={() => setActiveServiceTab("custom-websites-info")}
                  aria-controls="custom-websites-info-info"
                >
                  <LucideIcon
                    name="Globe"
                    strokeWidth={1.4}
                    size={28}
                    data-aos="fade-up"
                  />
                  <span className="d-block font-medium font-size-xl">
                    {Lang("customWebsites")}
                  </span>
                </button>
              </Col>
              <Col className="p-0">
                <button
                  className={`box flex-column align-items-center justify-content-center text-center gap-2  ${
                    activeServiceTab === "logo-identity-info" ? "active" : ""
                  }`}
                  onClick={() => setActiveServiceTab("logo-identity-info")}
                  aria-controls="logo-identity-info-info"
                >
                  <LucideIcon
                    name="Palette"
                    strokeWidth={1.4}
                    size={28}
                    data-aos="fade-up"
                  />
                  <span className="d-block font-medium font-size-xl">
                    {Lang("logoIdentity")}
                  </span>
                </button>
              </Col>
              <Col className="p-0">
                <button
                  className={`box flex-column align-items-center justify-content-center text-center gap-2  ${
                    activeServiceTab === "custom-software-info" ? "active" : ""
                  }`}
                  onClick={() => setActiveServiceTab("custom-software-info")}
                  aria-controls="custom-software-info-info"
                >
                  <LucideIcon
                    name="Code"
                    strokeWidth={1.4}
                    size={28}
                    data-aos="fade-up"
                  />
                  <span className="d-block font-medium font-size-xl">
                    {Lang("customSoftware")}
                  </span>
                </button>
              </Col>
              <Col className="p-0">
                <button
                  className={`box flex-column align-items-center justify-content-center text-center gap-2  ${
                    activeServiceTab === "online-presence-info" ? "active" : ""
                  }`}
                  onClick={() => setActiveServiceTab("online-presence-info")}
                  aria-controls="online-presence-info"
                >
                  <LucideIcon
                    name="ChartNoAxesCombined"
                    strokeWidth={1.4}
                    size={28}
                    data-aos="fade-up"
                  />
                  <span className="d-block font-medium font-size-xl">
                    {Lang("onlinePresence")}
                  </span>
                </button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="section">
        <Container>
          {activeServiceTab === "custom-websites-info" && (
            <div className="flex-column align-items-center gap-2 mt-2">
              <Row className="g-4 gx-4 flex-column-reverse flex-md-row">
                <Col md={6} data-aos="fade-right">
                  <div className="d-flex align-items-center">
                    <Row xs={1} md={2} className="boxes g-4">
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon
                            name="Monitor"
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("responsiveDesign")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("responsiveDesignDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon
                            name="Wrench"
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("customSolutions")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("customSolutionsDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon
                            name="ChartLine"
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("seoOptimization")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("seoOptimizationDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon
                            name="Settings"
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("maintenanceSupport")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("maintenanceSupportDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6} data-aos="fade-left">
                  <div className="d-flex flex-column gap-2">
                    <div className="font-regular font-size-xxl">
                      {Lang("transformBusiness")}
                    </div>
                    <span className="h2">{Lang("boostBusiness")}</span>
                    <p>{Lang("digitalTransformationDescription")}</p>
                    <p>{Lang("digitalTransformationSpecialists")}</p>
                    <ul className="list-unstyled mb-2">
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("customWebSolutions")}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("intuitiveInterfaces")}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("digitalIntegration")}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("seoPractices")}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {activeServiceTab === "logo-identity-info" && (
            <div className="flex-column align-items-center gap-2 mt-2">
              <Row className="g-4 gx-4 flex-column-reverse flex-md-row">
                <Col md={6}>
                  <div className="d-flex align-items-center">
                    <Row xs={1} md={2} className="boxes g-4">
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon
                            name="Brush"
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("logoDesign")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("logoDesignDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon
                            name="SwatchBook"
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("colorPalette")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("colorPaletteDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon 
                            name="Type" 
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("typography")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("typographyDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon
                            name="Layers"
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("brandMaterials")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("brandMaterialsDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex flex-column gap-2">
                    <div className="font-regular font-size-xxl">
                      {Lang("transformYourBrand")}
                    </div>
                    <span className="h2">
                      {Lang("developImpactfulVisualIdentity")}
                    </span>
                    <p>{Lang("visualIdentityDescription")}</p>
                    <p>{Lang("visualIdentitySpecialists")}</p>
                    <ul className="list-unstyled mb-2">
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("customLogoDesign")}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("harmoniousColorPalettes")}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("carefullySelectedTypography")}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("brandReinforcementMaterials")}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {activeServiceTab === "custom-software-info" && (
            <div className="flex-column align-items-center gap-2 mt-2">
              <Row className="g-4 gx-4 flex-column-reverse flex-md-row">
                <Col md={6}>
                  <div className="d-flex align-items-center">
                    <Row xs={1} md={2} className="boxes g-4">
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon 
                            name="Code" 
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("softwareDevelopment")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("softwareDevelopmentDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon
                            name="Layers"
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("systemIntegration")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("systemIntegrationDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon
                            name="Cloud"
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("cloudSolutions")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("cloudSolutionsDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon
                            name="Shield"
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("securityAndSupport")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("securityAndSupportDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex flex-column gap-2">
                    <div className="font-regular font-size-xxl">
                      {Lang("transformYourProcesses")}
                    </div>
                    <span className="h2">{Lang("enhanceYourBusiness")}</span>
                    <p>{Lang("softwareDescription")}</p>
                    <p>{Lang("softwareExperts")}</p>
                    <ul className="list-unstyled mb-2">
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("customSoftwareDevelopment")}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("systemIntegration")}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("cloudSolutions")}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("robustSecurity")}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {activeServiceTab === "online-presence-info" && (
            <div className="flex-column align-items-center gap-2 mt-2">
              <Row className="g-4 gx-4 flex-column-reverse flex-md-row">
                <Col md={6}>
                  <div className="d-flex align-items-center">
                    <Row xs={1} md={2} className="boxes g-4">
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon
                            name="Globe"
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("webDevelopment")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("webDevelopmentDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon
                            name="Search"
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("seoOptimization")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("seoOptimizationDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon
                            name="ThumbsUp"
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("socialMediaManagement")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("socialMediaManagementDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                          <LucideIcon
                            name="ChartNoAxesColumnIncreasing"
                            strokeWidth={1.4}
                            size={24}
                          />
                          <div className="d-flex flex-column gap-1 mt-1 mb-1">
                            <span className="d-block font-medium font-size-xl me-auto">
                              {Lang("performanceAnalysis")}
                            </span>
                            <span className="d-block font-size-lg">
                              {Lang("performanceAnalysisDescription")}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex flex-column gap-2">
                    <div className="font-regular font-size-xxl">
                      {Lang("strengthenDigitalPresence")}
                    </div>
                    <span className="h2">{Lang("maximizeVisibility")}</span>
                    <p>{Lang("digitalPresenceDescription")}</p>
                    <p>{Lang("digitalMarketingExperts")}</p>
                    <ul className="list-unstyled mb-2">
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("impactfulWebsiteDevelopment")}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("seoOptimizationService")}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("effectiveSocialMediaManagement")}</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                          <LucideIcon
                            name="Check"
                            strokeWidth={2}
                            size={18}
                            className="text-primary-color"
                          />
                          <span>{Lang("detailedPerformanceAnalysis")}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
      <section className="bg-secondary-tc">
        <Container>
          <div className="section-label">
            <h2 className="h2">{Lang("digitalTransformationServicesTitle")}</h2>
            <p>{Lang("digitalTransformationServicesDesc")}</p>
          </div>
          <Row xs={1} md={2} lg={4} className="boxes g-4">
            <Col data-aos="flip-left">
              <div className="box bg-primary-tc flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <LucideIcon 
                    name="Globe" 
                    strokeWidth={1.4}
                    size={28}
                  />
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("webDevelopment")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("webDevelopmentDesc")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-left">
              <div className="box bg-primary-tc flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <LucideIcon 
                    name="Palette" 
                    strokeWidth={1.4}
                    size={28}
                  />
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("logoDesign")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("logoDesignDesc")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-left">
              <div className="box bg-primary-tc flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <LucideIcon 
                    name="Code" 
                    strokeWidth={1.4}
                    size={28}
                  />
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("digitalMarketing")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("digitalMarketingDesc")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-left">
              <div className="box bg-primary-tc flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <LucideIcon 
                    name="Phone" 
                    strokeWidth={1.4}
                    size={28}
                  />
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("mobileApps")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("mobileAppsDesc")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-left">
              <div className="box bg-primary-tc flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <LucideIcon 
                    name="Search" 
                    strokeWidth={1.4}
                    size={28}
                  />
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("seoOptimization")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("seoOptimizationDesc")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-left">
              <div className="box bg-primary-tc flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <LucideIcon
                    name="ChartNoAxesColumnIncreasing"
                    strokeWidth={1.4}
                    size={28}
                  />
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("dataAnalysis")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("dataAnalysisDesc")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-left">
              <div className="box bg-primary-tc flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <LucideIcon
                    name="Layers"
                    strokeWidth={1.4}
                    size={28}
                  />
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("systemIntegration")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("systemIntegrationDesc")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-left">
              <div className="box bg-primary-tc flex-column p-4 gap-2">
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <LucideIcon
                    name="User"
                    strokeWidth={1.4}
                    size={28}
                  />
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("consulting")}
                  </span>
                  <span className="d-block font-size-lg">
                    {Lang("consultingDesc")}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row xs={1} lg={2} className="boxes g-4">
            <Col md={6} data-aos="zoom-in-right">
              <div className="d-flex flex-column align-items-start gap-3 me-0 me-md-5">
                <div className="label">
                  <h3 className="h4">{Lang("ourExpertiseTitle")}</h3>
                  <p>{Lang("ourExpertiseDesc")}</p>
                </div>
                <div
                  className="w-100 btn-group d-flex flex-column align-items-start"
                  role="tablist"
                >
                  <Button
                    className={`btn btn-lg btn-outline-primary pt-2 pb-2 w-100 text-start ${
                      activeServiceInfoTab === "custom-websites-info"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      setActiveServiceInfoTab("custom-websites-info")
                    }
                    aria-controls="custom-websites-info-info"
                  >
                    {Lang("customWebsites")}
                  </Button>
                  <Button
                    className={`btn btn-lg btn-outline-primary pt-2 pb-2 w-100 text-start ${
                      activeServiceInfoTab === "logo-identity-info"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      setActiveServiceInfoTab("logo-identity-info")
                    }
                    aria-controls="logo-identity-info-info"
                  >
                    {Lang("logoDesign")}
                  </Button>
                  <Button
                    className={`btn btn-lg btn-outline-primary pt-2 pb-2 w-100 text-start ${
                      activeServiceInfoTab === "custom-software-info"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      setActiveServiceInfoTab("custom-software-info")
                    }
                    aria-controls="custom-software-info-info"
                  >
                    {Lang("customSoftware")}
                  </Button>
                  <Button
                    className={`btn btn-lg btn-outline-primary pt-2 pb-2 w-100 text-start ${
                      activeServiceInfoTab === "online-presence-info"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      setActiveServiceInfoTab("online-presence-info")
                    }
                    aria-controls="online-presence-info-info"
                  >
                    {Lang("onlinePresence")}
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={6} data-aos="zoom-in-left">
              {activeServiceInfoTab === "custom-websites-info" && (
                <div className="box box-info h-100 flex-column align-items-start align-items-md-center justify-content-start position-relative gap-4">
                  <div className="image image-wrapper lg rounded-1">
                    <img src={designer01} alt="designer" />
                  </div>
                  <div className="content bg-secondary-tc rounded-1 d-flex flex-column p-content">
                    <span className="h6">{Lang("customWebsites")}</span>
                    <p>{Lang("customWebsitesDesc")}</p>
                  </div>
                </div>
              )}
              {activeServiceInfoTab === "logo-identity-info" && (
                <div className="box box-info h-100 flex-column align-items-start align-items-md-center justify-content-start position-relative gap-4">
                  <div className="image image-wrapper lg rounded-1">
                    <img src={designer02} alt="logo design" />
                  </div>
                  <div className="content bg-secondary-tc rounded-1 d-flex flex-column p-content">
                    <span className="h6">{Lang("logoDesign")}</span>
                    <p>{Lang("logoDesignDesc")}</p>
                  </div>
                </div>
              )}
              {activeServiceInfoTab === "custom-software-info" && (
                <div className="box box-info h-100 flex-column align-items-start align-items-md-center justify-content-start position-relative gap-4">
                  <div className="image image-wrapper lg rounded-1">
                    <img src={programmer} alt="software development" />
                  </div>
                  <div className="content bg-secondary-tc rounded-1 d-flex flex-column p-content">
                    <span className="h6">{Lang("customSoftware")}</span>
                    <p>{Lang("customSoftwareDesc")}</p>
                  </div>
                </div>
              )}
              {activeServiceInfoTab === "online-presence-info" && (
                <div className="box box-info h-100 flex-column align-items-start align-items-md-center justify-content-start position-relative gap-4">
                  <div className="image image-wrapper lg rounded-1">
                    <img src={homepage} alt="online presence" />
                  </div>
                  <div className="content bg-secondary-tc rounded-1 d-flex flex-column p-content">
                    <span className="h6">{Lang("onlinePresence")}</span>
                    <p>{Lang("onlinePresenceDesc")}</p>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-secondary-tc">
        <Container>
          <div className="section-label">
            <h4 className="h2">{Lang("digitalTransformationTitle")}</h4>
            <p>{Lang("digitalTransformationDesc")}</p>
          </div>
          <Row xs={1} lg={2} className="boxes">
            <Col data-aos="zoom-in">
              <div className="box flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center bg-white row-gap-1 gap-3 rounded-1 border text-color hover-shadow">
                <LucideIcon
                  name="Cog"
                  strokeWidth={1.2}
                  size={30}
                />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("operationalEfficiency")}
                  </span>
                  <span className="d-block font-size-sm">
                    {Lang("operationalEfficiencyDesc")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in">
              <div className="box flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center bg-white row-gap-1 gap-3 rounded-1 border text-color hover-shadow">
                <LucideIcon
                  name="MessageSquareQuote"
                  strokeWidth={1.2}
                  size={30}
                />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("customerExperience")}
                  </span>
                  <span className="d-block font-size-sm">
                    {Lang("customerExperienceDesc")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in">
              <div className="box flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center bg-white row-gap-1 gap-3 rounded-1 border text-color hover-shadow">
                <LucideIcon
                  name="ChartLine"
                  strokeWidth={1.2}
                  size={30}
                />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("advancedAnalytics")}
                  </span>
                  <span className="d-block font-size-sm">
                    {Lang("advancedAnalyticsDesc")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in">
              <div className="box flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center bg-white row-gap-1 gap-3 rounded-1 border text-color hover-shadow">
                <LucideIcon
                  name="Rocket"
                  strokeWidth={1.2}
                  size={30}
                />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("agilityFlexibility")}
                  </span>
                  <span className="d-block font-size-sm">
                    {Lang("agilityFlexibilityDesc")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in">
              <div className="box flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center bg-white row-gap-1 gap-3 rounded-1 border text-color hover-shadow">
                <LucideIcon
                  name="Banknote"
                  strokeWidth={1.2}
                  size={30}
                />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("costReduction")}
                  </span>
                  <span className="d-block font-size-sm">
                    {Lang("costReductionDesc")}
                  </span>
                </div>
              </div>
            </Col>
            <Col data-aos="zoom-in">
              <div className="box flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center bg-white row-gap-1 gap-3 rounded-1 border text-color hover-shadow">
                <LucideIcon
                  name="Trophy"
                  strokeWidth={1.2}
                  size={30}
                />
                <div className="d-flex flex-column gap-1 mt-1 mb-1">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("increasedCompetitiveness")}
                  </span>
                  <span className="d-block font-size-sm">
                    {Lang("increasedCompetitivenessDesc")}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <AdvantagesSection className="pb-4" />
    </>
  );
};

export default DigitalTransformation;