import React from "react";
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  ListGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import LucideIcon from "../components/common/LucideIcon";
import { Lang } from "../components/language/LanguageProvider";
import AdvantagesSection from "../components/common/AdvantagesSection";
import CarouselServices from "../components/common/CarouselServices";

// Assets
import tiManOnComputer from "../assets/img/design/ti-man-on-computer.png";

const Home = () => {
  return (
    <>
      <div className="thumb bg-secondary-tc pt-2">
        <Container>
          <div className="section-label mb-4">
            <div className="h6 font-regular mb-3">
              {Lang("solutions_heading")}
            </div>
            <div className="h1">{Lang("digital_journey")}</div>
          </div>
          <div className="d-flex flex-column align-items-center gap-5">
            <ButtonGroup aria-label="Services">
              <Button
                as={Link}
                to="/cybersecurity"
                variant="primary"
                size="lg"
                data-aos="flip-up"
              >
                {Lang("cybersecurity")}
              </Button>
              <Button
                as={Link}
                to="/infrastructure-and-networks"
                variant="primary"
                size="lg"
                data-aos="flip-up"
              >
                {Lang("infrastructure_networks")}
              </Button>
              <Button
                as={Link}
                to="/digital-transformation"
                variant="primary"
                size="lg"
                data-aos="flip-up"
              >
                {Lang("digital_transformation")}
              </Button>
              <Button
                as={Link}
                to="/it-consulting"
                variant="primary"
                size="lg"
                data-aos="flip-up"
              >
                {Lang("it_consulting")}
              </Button>
              <Button
                as={Link}
                to="/software-management"
                variant="primary"
                size="lg"
                data-aos="flip-up"
              >
                {Lang("software_management")}
              </Button>
              <Button
                as={Link}
                to="/development"
                variant="primary"
                size="lg"
                data-aos="flip-up"
              >
                {Lang("development")}
              </Button>
            </ButtonGroup>
            <Row xs={1} lg={2} className="boxes">
              <Col data-aos="fade-right">
                <div className="box flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center bg-white row-gap-1 gap-3 rounded-1 border text-color hover-shadow">
                  <LucideIcon
                    name="Code"
                    strokeWidth={1.2}
                    size={30}
                    className="lucide"
                  />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("software_development_management")}
                    </span>
                    <span className="d-block font-size-sm">
                      {Lang("software_development_description")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="fade-left">
                <div className="box flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center bg-white row-gap-1 gap-3 rounded-1 border text-color hover-shadow">
                  <LucideIcon
                    name="ShieldCheck"
                    strokeWidth={1.2}
                    size={30}
                    className="lucide"
                  />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("information_security")}
                    </span>
                    <span className="d-block font-size-sm">
                      {Lang("information_security_description")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="fade-right">
                <div className="box flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center bg-white row-gap-1 gap-3 rounded-1 border text-color hover-shadow">
                  <LucideIcon
                    name="ChartNoAxesCombined"
                    strokeWidth={1.2}
                    size={30}
                    className="lucide"
                  />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("it_consulting_digital_transformation")}
                    </span>
                    <span className="d-block font-size-sm">
                      {Lang("it_consulting_digital_transformation_description")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="fade-left">
                <div className="box flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center bg-white row-gap-1 gap-3 rounded-1 border text-color hover-shadow">
                  <LucideIcon
                    name="Server"
                    strokeWidth={1.2}
                    size={30}
                    className="lucide"
                  />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("infrastructure_networks")}
                    </span>
                    <span className="d-block font-size-sm">
                      {Lang("infrastructure_networks_description")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="fade-right">
                <div className="box flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center bg-white row-gap-1 gap-3 rounded-1 border text-color hover-shadow">
                  <LucideIcon
                    name="CalendarCheck"
                    strokeWidth={1.2}
                    size={30}
                    className="lucide"
                  />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("project_management")}
                    </span>
                    <span className="d-block font-size-sm">
                      {Lang("project_management_description")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="fade-left">
                <div className="box flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center bg-white row-gap-1 gap-3 rounded-1 border text-color hover-shadow">
                  <LucideIcon
                    name="Headphones"
                    strokeWidth={1.2}
                    size={30}
                    className="lucide"
                  />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("technical_support_help_desk")}
                    </span>
                    <span className="d-block font-size-sm">
                      {Lang("technical_support_help_desk_description")}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <section>
        <Container>
          <Row className="g-4">
            <Col md={6} data-aos="fade-right">
              <div className="bg-decoration d-flex align-items-center justify-content-center pt-5">
                <div className="image-wrapper lg rounded-1 mt-4">
                  <img src={tiManOnComputer} alt={Lang("image_alt")} />
                </div>
              </div>
            </Col>
            <Col md={6} data-aos="fade-left">
              <div className="d-flex flex-column align-items-start gap-3">
                <div className="label">
                  <h1 className="h4">
                    {Lang("it_consulting_digital_transformation")}
                  </h1>
                  <p className="h6 font-regular">
                    {Lang("it_consulting_description")}
                  </p>
                </div>
                <Button
                  as={Link}
                  to="/it-consulting"
                  className="btn-lg btn-primary"
                >
                  {Lang("learn_more")}
                </Button>
                <ListGroup className="list-unstyled">
                  <ListGroup.Item>
                    <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                      <LucideIcon
                        name="Check"
                        strokeWidth={2}
                        size={18}
                        className="bg-primary rounded-5 text-success"
                      />
                      <span className="me-auto">{Lang("custom_plans")}</span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                      <LucideIcon
                        name="Check"
                        strokeWidth={2}
                        size={18}
                        className="bg-primary rounded-5 text-success"
                      />
                      <span>{Lang("recent_innovations")}</span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                      <LucideIcon
                        name="Check"
                        strokeWidth={2}
                        size={18}
                        className="bg-primary rounded-5 text-success"
                      />
                      <span>{Lang("ongoing_support")}</span>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="p-0">
        <div className="section position-relative">
          <div className="bg-hands-on-laptop">
            <Container>
              <div className="d-flex flex-column align-items-start gap-3">
                <div className="label" data-aos="fade-right">
                  <h2 className="h4">{Lang("information_security_heading")}</h2>
                  <p className="font-size-xl font-regular">
                    {Lang("information_security_description")}
                  </p>
                </div>
                <Button
                  as={Link}
                  to="/cybersecurity"
                  className="btn-lg btn-primary"
                  data-aos="fade-left"
                >
                  {Lang("learn_more")}
                </Button>
              </div>
            </Container>
          </div>
        </div>
      </section>
      <section>
        <Container>
          <div
            className="d-flex flex-column align-items-start gap-3"
            data-aos="zoom-in"
          >
            <div className="label">
              <h3 className="h4">{Lang("software_development_heading")}</h3>
              <p className="h6 font-regular">
                {Lang("software_development_description")}
              </p>
            </div>
            <Button as={Link} to="/development" className="btn-lg btn-primary">
              {Lang("discover_solutions")}
            </Button>
            <ul className="list-unstyled">
              <li>
                <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                  <i
                    data-lucide="check"
                    strokeWidth={2}
                    size={18}
                    className="text-success"
                  ></i>
                  <span className="me-auto">
                    <strong>{Lang("development")}</strong>:{" "}
                    {Lang("development_description")}
                  </span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                  <i
                    data-lucide="check"
                    strokeWidth={2}
                    size={18}
                    className="text-success"
                  ></i>
                  <span>
                    <strong>{Lang("complete_lifecycle_management")}</strong>:{" "}
                    {Lang("lifecycle_management_description")}
                  </span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                  <i
                    data-lucide="check"
                    strokeWidth={2}
                    size={18}
                    className="text-success"
                  ></i>
                  <span>
                    <strong>{Lang("scalability_and_flexibility")}</strong>:{" "}
                    {Lang("scalability_description")}
                  </span>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                  <i
                    data-lucide="check"
                    strokeWidth={2}
                    size={18}
                    className="text-success"
                  ></i>
                  <span>
                    <strong>{Lang("technical_support")}</strong>:{" "}
                    {Lang("support_description")}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </Container>
      </section>
      <section className="p-0">
        <div className="section bg-gray-light position-relative">
          <div className="bg-waves pt-5 pb-5">
            <Container>
              <div
                className="d-flex flex-column align-items-center gap-2"
                data-aos="zoom-in"
              >
                <div className="section-label mb-2">
                  <h4 className="h2">
                    {Lang("infrastructure_networks_heading")}
                  </h4>
                </div>
                <Button
                  as={Link}
                  to="/infrastructure-and-networks"
                  className="btn-lg btn-primary rounded-5"
                >
                  {Lang("request_free_consultation")}
                </Button>
              </div>
            </Container>
          </div>
        </div>
      </section>
      <CarouselServices />
      <AdvantagesSection className="pt-0 pb-4" />
    </>
  );
};

export default Home;