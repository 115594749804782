import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from "./Header";
import Footer from "./Footer";
import { Lang } from "../language/LanguageProvider";

const Application = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  AOS.init();

  return (
    <>
      <Helmet>
        <title>{Lang("pageTitle")}</title>
      </Helmet>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Application;