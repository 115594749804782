import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import LucideIcon from "../components/common/LucideIcon";
import { Lang } from "../components/language/LanguageProvider";
import AdvantagesSection from "../components/common/AdvantagesSection";

const ContactUs = () => {
  return (
    <>
      <div className="thumb bg-secondary-tc pt-2">
        <Container>
          <div className="section-label d-flex flex-column justify-content-center align-items-center mb-4">
            <span
              className="badge rounded-pill text-bg-color mb-3"
              data-aos="fade-down"
            >
              {Lang("contactUs")}
            </span>
            <div className="h1">{Lang("readyToAnswer")}</div>
          </div>
          <Row xs={1} md={2} lg={3} className="boxes mb-3 justify-content-center">
            <Col data-aos="fade-right">
              <div className="box flex-row bg-white gap-1 rounded-1 border text-color gap-3">
                <div className="bg-primary w-mc h-mc d-flex align-items-center justify-content-center text-white p-1 rounded-5">
                  <LucideIcon
                    name="Phone"
                    strokeWidth={1.8}
                    size={20.8}
                    className="text-white m-2"
                  />
                </div>
                <div className="d-flex flex-column">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("phone")}
                  </span>
                  <span className="d-block font-size-md">+1 418 476 9653</span>
                  <a href="tel:+14184769653">{Lang("callNow")}</a>
                </div>
              </div>
            </Col>
            <Col data-aos="fade-up">
              <div className="box flex-row bg-white gap-1 rounded-1 border text-color gap-3">
                <div className="bg-primary w-mc h-mc d-flex align-items-center justify-content-center text-white p-1 rounded-5">
                  <LucideIcon
                    name="Mail"
                    strokeWidth={1.8}
                    size={20.8}
                    className="text-white m-2"
                  />
                </div>
                <div className="d-flex flex-column">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("email")}
                  </span>
                  <span className="d-block font-size-md">contact@msxp.ca</span>
                  <a href="mailto:contact@msxp.ca">{Lang("sendEmail")}</a>
                </div>
              </div>
            </Col>
            <Col data-aos="fade-left">
              <div className="box flex-row bg-white gap-1 rounded-1 border text-color gap-3">
                <div className="bg-primary w-mc h-mc d-flex align-items-center justify-content-center text-white p-1 rounded-5">
                  <LucideIcon
                    name="MapPin"
                    strokeWidth={1.8}
                    size={20.8}
                    className="text-white m-2"
                  />
                </div>
                <div className="d-flex flex-column">
                  <span className="d-block font-medium font-size-xl me-auto">
                    {Lang("address")}
                  </span>
                  <span className="d-block font-size-md">
                    {Lang("addressDescription")}
                  </span>
                  <a
                    href="https://www.google.com/maps"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {Lang("viewOnMap")}
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <section>
        <Container>
          <div className="d-flex flex-column">
            <h1 className="h4 font-medium mb-2">
              {Lang("preferToFillOutForm")}
            </h1>
            <Form
              id="contact-form"
              action="#"
              className="needs-validation"
              noValidate
            >
              <Row className="g-3">
                <Col md={6} data-aos="fade-right">
                  <Form.Group
                    controlId="fname"
                    className="d-flex flex-column gap-1"
                  >
                    <Form.Label className="font-medium font-size-xl">
                      {Lang("name")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="fname"
                      placeholder={Lang("namePlaceholder")}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {Lang("nameValidation")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} data-aos="fade-left">
                  <Form.Group
                    controlId="email"
                    className="d-flex flex-column gap-1"
                  >
                    <Form.Label className="font-medium font-size-xl">
                      {Lang("email")}
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder={Lang("emailPlaceholder")}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {Lang("emailValidation")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12} data-aos="fade-up">
                  <Form.Group
                    controlId="message"
                    className="d-flex flex-column gap-1"
                  >
                    <Form.Label className="font-medium font-size-xl">
                      {Lang("message")}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="message"
                      rows={6}
                      placeholder={Lang("messagePlaceholder")}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {Lang("messageValidation")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12} data-aos="fade-left">
                  <div className="d-flex align-items-start flex-column gap-3 flex-md-row align-items-md-center gap-md-4">
                    <Button type="submit" className="btn btn-lg btn-primary">
                      {Lang("sendMessage")}
                    </Button>
                    <div className="d-flex align-items-center gap-2">
                      <LucideIcon
                        name="Info"
                        strokeWidth={2}
                        size={18}
                      />
                      <span>{Lang("requiredFields")}</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      </section>
      <AdvantagesSection className="pt-0 pb-4" />
    </>
  );
};

export default ContactUs;