import { useContext, useState, useEffect } from "react";
import { NavDropdown, Image } from "react-bootstrap";
import {
  Lang,
  LanguageContext,
  getAllLanguages,
} from "../language/LanguageProvider";

const LanguageDropdown = () => {
  const { switchLanguage, langDetails } = useContext(LanguageContext);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const fetchLanguages = async () => {
      const fetchedLanguages = await getAllLanguages();
      setLanguages(fetchedLanguages);
    };

    fetchLanguages();
  }, []);

  const flag = (lang) => {
    try {
      return (
        <Image
          className="image-wrapper"
          src={require(`../../assets/img/countries/${lang.sigle}.svg`)}
          alt={`${lang.sigle} flag`}
        />
      );
    } catch {
      return "";
    }
  };
  const selectedText = Lang("selected");
  return (
    <NavDropdown title={langDetails?.sigle ? langDetails.sigle.toUpperCase() : "Default"} id="language-dropdown" className="dropdown-lang">
      {languages.map((lang) => {
        return (
          <NavDropdown.Item
            href="#"
            className="d-flex align-items-center gap-2"
            key={lang.sigle}
            onClick={() => switchLanguage(lang.sigle)}
          >
            <div className="d-flex align-items-center gap-2">
              {flag(lang)}
              <span>
                {lang.name} - {lang.country}{" "}
                {langDetails.sigle === lang.sigle && (
                  <strong className="text-dominant-color">
                    ({selectedText})
                  </strong>
                )}
              </span>
            </div>
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
};

export default LanguageDropdown;