import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import LucideIcon from "../components/common/LucideIcon";
import { Lang } from "../components/language/LanguageProvider";
import Logo from "../components/common/Logo";
import AdvantagesSection from "../components/common/AdvantagesSection";

const Development = () => {
  return (
    <>
      <div className="thumb bg-secondary-tc pt-2">
        <Container>
          <div className="section-label d-flex flex-column justify-content-center align-items-center mb-4">
            <div className="h1">{Lang("softwareDevelopmentManagement")}</div>
            <p>{Lang("comprehensiveSolutions")}</p>
          </div>
          <Row xs={1} md={2} lg={4} className="g-3">
            <Col data-aos="flip-left">
              <div className="h-100 bg-primary-tc d-flex align-items-center border rounded-1 p-3 gap-3">
                <span className="font-semi-bold font-size-big">1</span>
                <div className="d-flex flex-column">
                  <span className="font-medium">
                    {Lang("requirementsAnalysis")}
                  </span>
                  <p className="m-0">{Lang("requirementsAnalysisDesc")}</p>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-left">
              <div className="h-100 bg-primary-tc d-flex align-items-center border rounded-1 p-3 gap-3">
                <span className="font-semi-bold font-size-big">2</span>
                <div className="d-flex flex-column">
                  <span className="font-medium">
                    {Lang("designPrototyping")}
                  </span>
                  <p className="m-0">{Lang("designPrototypingDesc")}</p>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-right">
              <div className="h-100 bg-primary-tc d-flex align-items-center border rounded-1 p-3 gap-3">
                <span className="font-semi-bold font-size-big">3</span>
                <div className="d-flex flex-column">
                  <span className="font-medium">
                    {Lang("agileDevelopment")}
                  </span>
                  <p className="m-0">{Lang("agileDevelopmentDesc")}</p>
                </div>
              </div>
            </Col>
            <Col data-aos="flip-right">
              <div className="h-100 bg-primary-tc d-flex align-items-center border rounded-1 p-3 gap-3">
                <span className="font-semi-bold font-size-big">4</span>
                <div className="d-flex flex-column">
                  <span className="font-medium">{Lang("implementation")}</span>
                  <p className="m-0">{Lang("implementationDesc")}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <section>
        <Container>
          <div className="d-flex flex-column align-items-center gap-2">
            <div className="section-label">
              <h1 className="h2">{Lang("customSoftwareSolutions")}</h1>
              <p>{Lang("completeServiceRange")}</p>
            </div>
            <Row xs={1} md={2} className="boxes w-100 g-5 g-md-4">
              <Col data-aos="fade-right">
                <div className="box flex-column p-0 gap-2">
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("agileDevelopment")}
                    </span>
                  </div>
                  <ul className="list-unstyled">
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          size={18}
                          className="text-success"
                        />
                        <span className="me-auto">{Lang("quickDelivery")}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          size={18}
                          className="text-success"
                        />
                        <span className="me-auto">
                          {Lang("continuousFeedback")}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col data-aos="fade-right">
                <div className="box flex-column p-0 gap-2">
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("systemIntegration")}
                    </span>
                  </div>
                  <ul className="list-unstyled">
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          size={18}
                          className="text-success"
                        />
                        <span className="me-auto">
                          {Lang("seamlessIntegration")}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          size={18}
                          className="text-success"
                        />
                        <span className="me-auto">
                          {Lang("efficiencyImprovement")}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col data-aos="fade-left">
                <div className="box flex-column p-0 gap-2">
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("continuousSupport")}
                    </span>
                  </div>
                  <ul className="list-unstyled">
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          size={18}
                          className="text-success"
                        />
                        <span className="me-auto">{Lang("24x7Support")}</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          size={18}
                          className="text-success"
                        />
                        <span className="me-auto">
                          {Lang("proactiveMonitoring")}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col data-aos="fade-left">
                <div className="box flex-column p-0 gap-2">
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("regularUpdates")}
                    </span>
                  </div>
                  <ul className="list-unstyled">
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          size={18}
                          className="text-success"
                        />
                        <span className="me-auto">
                          {Lang("regularMaintenanceFor")}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center gap-2 mt-2 mb-2">
                        <LucideIcon
                          name="Check"
                          size={18}
                          className="text-success"
                        />
                        <span className="me-auto">
                          {Lang("continuousEnhancements")}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="bg-secondary-tc">
        <Container>
          <div className="d-flex flex-column align-items-center gap-2">
            <div className="section-label">
              <h2 className="h2">{Lang("transformYourBusiness")}</h2>
              <p>{Lang("eachServiceOptimized")}</p>
            </div>
            <Row xs={1} md={2} lg={4} className="boxes g-4">
              <Col data-aos="fade-up-right">
                <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                  <LucideIcon name="ShieldAlert" size={26} strokeWidth={1.4} />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("customDevelopment")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("customDevelopmentDescription")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="fade-up-right">
                <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                  <LucideIcon name="Siren" size={26} strokeWidth={1.4} />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("projectManagement")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("projectManagementDescription")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="fade-up-left">
                <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                  <LucideIcon name="Cloud" size={26} strokeWidth={1.4} />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("continuousMaintenance")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("continuousMaintenanceDescription")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="fade-up-left">
                <div className="box bg-primary-tc border rounded-1 flex-column p-4 gap-2">
                  <LucideIcon name="FileCheck" size={26} strokeWidth={1.4} />
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl me-auto">
                      {Lang("systemIntegration")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("systemIntegrationDescription")}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section>
        <Container>
          <div className="d-flex flex-column align-items-center gap-2">
            <div className="section-label">
              <h3 className="h2">{Lang("comprehensiveSolutions")}</h3>
              <p>{Lang("msxpConnectServices")}</p>
            </div>
            <Row xs={1} md={2} className="boxes g-4">
              <Col data-aos="zoom-in">
                <div className="box text-center flex-column p-4 gap-2">
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl">
                      {Lang("specializedConsulting")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("specializedConsultingDescription")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="zoom-in">
                <div className="box text-center flex-column p-4 gap-2">
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl">
                      {Lang("agileDevelopment")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("agileDevelopmentDescription")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="zoom-in">
                <div className="box text-center flex-column p-4 gap-2">
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl">
                      {Lang("systemIntegration")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("systemIntegrationDescription")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="zoom-in">
                <div className="box text-center flex-column p-4 gap-2">
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl">
                      {Lang("softwareSecurity")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("softwareSecurityDescription")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="zoom-in">
                <div className="box text-center flex-column p-4 gap-2">
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl">
                      {Lang("continuousSupport")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("continuousSupportDescription")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col data-aos="zoom-in">
                <div className="box text-center flex-column p-4 gap-2">
                  <div className="d-flex flex-column gap-1 mt-1 mb-1">
                    <span className="d-block font-medium font-size-xl">
                      {Lang("updatesAndMaintenance")}
                    </span>
                    <span className="d-block font-size-lg">
                      {Lang("updatesAndMaintenanceDescription")}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="bg-secondary-tc">
        <div className="container">
          <div
            className="d-flex flex-column align-items-center gap-2"
            data-aos="zoom-in"
          >
            <div className="logo lg">
              <Logo />
            </div>
            <div className="section-label mb-0">
              <h5 className="h2">
                {Lang("readyToRevolutionizeSoftwareManagement")}
              </h5>
              <p>{Lang("msxpConnectReadyDescription")}</p>
            </div>
            <Link to="/contact-us" className="btn btn-lg btn-primary rounded-5">
              {Lang("contactUs")}
            </Link>
          </div>
        </div>
      </section>
      <section className="pb-0">
        <Container>
          <div className="section-label">
            <h5 className="h2">{Lang("frequentlyAskedQuestions")}</h5>
            <p>{Lang("faqDescription")}</p>
          </div>
          <Accordion
            defaultActiveKey="0"
            className="accordion-flush"
            id="accordionQuestion"
          >
            <Accordion.Item
              eventKey="0"
              className="border mt-2 mb-2"
              data-aos="zoom-out-up"
            >
              <Accordion.Header>
                {Lang("whatIsCustomSoftwareDevelopment")}
              </Accordion.Header>
              <Accordion.Body className="pt-0">
                {Lang("customSoftwareDevelopmentDescription")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="1"
              className="border mt-2 mb-2"
              data-aos="zoom-out-up"
            >
              <Accordion.Header>
                {Lang("benefitsOfSoftwareIntegration")}
              </Accordion.Header>
              <Accordion.Body className="pt-0">
                {Lang("softwareIntegrationBenefits")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="2"
              className="border mt-2 mb-2"
              data-aos="zoom-out-up"
            >
              <Accordion.Header>
                {Lang("howDoesTechnicalSupportWork")}
              </Accordion.Header>
              <Accordion.Body className="pt-0">
                {Lang("technicalSupportDescription")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="3"
              className="border mt-2 mb-2"
              data-aos="zoom-out-up"
            >
              <Accordion.Header>
                {Lang("whatIsSoftwareProjectManagement")}
              </Accordion.Header>
              <Accordion.Body className="pt-0">
                {Lang("softwareProjectManagementDescription")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="4"
              className="border mt-2 mb-2"
              data-aos="zoom-out-up"
            >
              <Accordion.Header>
                {Lang("howDoesMSXPConnectEnsureDataSecurity")}
              </Accordion.Header>
              <Accordion.Body className="pt-0">
                {Lang("dataSecurityDescription")}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </section>
      <AdvantagesSection className="pb-4" />
    </>
  );
};

export default Development;