import React, { createContext, useContext, useState, useEffect } from "react";

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [langName, setLangName] = useState(localStorage.getItem('langName') || "en-us");
  const [lang, setLang] = useState(null);
  const [langDetails, setLangDetails] = useState({});

  useEffect(() => {
    const loadLanguage = async () => {
      try {
        const languageModule = await import(`./lang/${langName}`);
        setLang(languageModule.default.language);
        setLangDetails(languageModule.default.details);
      } catch (error) {
        console.error(`Error loading language file: ${error}`);
      }
    };

    loadLanguage();
  }, [langName]);

  const switchLanguage = (newLangName) => {
    localStorage.setItem('langName', newLangName);
    setLangName(newLangName);
  };

  return (
    <LanguageContext.Provider value={{ lang, langName, langDetails, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

const Lang = (key, ...values) => {
  const { lang } = useContext(LanguageContext);
  if (!lang) return;
  const translation = lang[key] || key;
  if (typeof translation === "function") {
    return translation(...values);
  }
  if (values.length >= 1) {
    return [key, ...values].map((value) => lang[value]).join(" ");
  }
  return translation;
};

const getAllLanguages = async () => {
  const context = require.context("./lang/", false, /\.js$/);
  const languages = await Promise.all(
    context.keys().map(async (key) => {
      const module = await import(`./lang/${key.replace("./", "")}`);
      return module.default.details;
    })
  );
  return languages;
};

export { LanguageContext, LanguageProvider, Lang, getAllLanguages };