const language = () => {
    const language = {
        "details": {
            "name": "Português",
            "sigle": "pt-br",
            "country": "Brasil"
        },
        "language": {
            // Title
            "pageTitle": "MSXP Connect - Soluções Inovadoras para o Futuro da Tecnologia",
            // Links
            "home": "Início",
            "services": "Serviços",
            "infrastructureAndNetworks": "Infraestrutura e Redes",
            "digitalTransformation": "Transformação Digital",
            "itConsulting": "Consultoria de TI",
            "softwareManagement": "Gestão de Software",
            "development": "Desenvolvimento",
            "msxpConnect": "MSXP Connect",
            "aboutUs": "Sobre Nós",
            "contactUs": "Fale Conosco",
            "termsOfService": "Termos de Serviço",
            "termsOfPrivacy": "Política de Privacidade",
            "loginToAccount": "Entrar na conta",
            "footerDescription": "MSXP Connect: Soluções inovadoras em software, segurança da informação e infraestrutura para transformar o seu negócio.",
            "emailLabel": "E-mail",
            "phoneLabel": "Telefone",
            "headquartersText": "Sede em Londres, Reino Unido.",
            // Others
            "solutions_heading": "Soluções Inovadoras e Personalizadas para Sua Empresa.",
            "digital_journey": "Acelerando Sua Jornada Digital com Expertise em Software, Segurança e Infraestrutura.",
            "cybersecurity": "Cibersegurança",
            "infrastructure_networks": "Infraestrutura e Redes",
            "digital_transformation": "Transformação Digital",
            "it_consulting": "Consultoria de TI",
            "software_management": "Gestão de Software",
            "software_development_management": "Desenvolvimento e Gestão de Software",
            "information_security": "Segurança da Informação",
            "information_security_description": "Protegemos seus dados com soluções avançadas de segurança, garantindo a confidencialidade e integridade das informações.",
            "it_consulting_digital_transformation": "Consultoria de TI e Transformação Digital",
            "it_consulting_digital_transformation_description": "Ajudamos sua empresa a adotar as melhores práticas e tecnologias para impulsionar a transformação digital.",
            "infrastructure_networks_description": "Projetamos e gerenciamos redes e infraestrutura para garantir eficiência operacional e escalabilidade.",
            "project_management": "Gestão de Projetos",
            "project_management_description": "Gerenciamos projetos de TI com precisão e eficiência, garantindo a entrega no prazo e dentro do orçamento.",
            "technical_support_help_desk": "Suporte Técnico e Help Desk",
            "technical_support_help_desk_description": "Oferecemos suporte técnico 24/7 para resolver problemas rapidamente e manter suas operações funcionando sem interrupções.",
            "image_alt": "Profissional de TI trabalhando em um computador",
            "learn_more": "Saiba Mais",
            "custom_plans": "Planos personalizados desenvolvidos para atender às necessidades exclusivas do seu negócio.",
            "recent_innovations": "Inovações recentes implementadas para manter sua empresa na vanguarda tecnológica.",
            "ongoing_support": "Suporte contínuo para garantir uma transformação digital bem-sucedida e sustentável.",
            "information_security_heading": "Segurança da Informação: Proteja Seu Negócio Contra Ameaças Cibernéticas",
            "software_development_heading": "Desenvolvimento e Gestão de Software: Soluções Personalizadas para Sua Empresa",
            "complete_lifecycle_management": "Gestão Completa do Ciclo de Vida",
            "scalability_and_flexibility": "Escalabilidade e Flexibilidade",
            "support_description": "Integração perfeita com sistemas existentes e suporte técnico dedicado para resolver problemas rapidamente e manter a continuidade dos negócios.",
            "infrastructure_networks_heading": "Precisa de Infraestrutura e Redes Robustas para Seu Data Center ou Provedor de Internet? Entre em Contato Agora!",
            "request_free_consultation": "Solicite uma Consulta Gratuita",
            "proven_experience": "Experiência Comprovada",
            "custom_solutions": "Soluções Personalizadas",
            "dedicated_support": "Suporte Dedicado",
            "carousel_section_title": "Desenvolvimento e Gestão de Software: Soluções Personalizadas para Sua Empresa",
            "software_development_title": "Desenvolvimento e Gestão de Software: Soluções Personalizadas para Sua Empresa",
            "software_development_description": "Oferecemos desenvolvimento de software personalizado e gestão abrangente para atender às necessidades específicas do seu negócio.",
            "discover_solutions": "Descubra Nossas Soluções",
            "development_description": "Soluções personalizadas para atender às necessidades exclusivas do seu negócio.",
            "lifecycle_management": "Gestão Completa do Ciclo de Vida",
            "lifecycle_management_description": "Monitoramento contínuo desde a criação até a manutenção.",
            "scalability": "Escalabilidade e Flexibilidade",
            "scalability_description": "Soluções escaláveis que crescem com sua empresa.",
            "technical_support": "Suporte Técnico",
            "technical_support_description": "Suporte técnico dedicado para resolver problemas rapidamente e manter a continuidade dos negócios.",
            "contact_us_question": "Tem perguntas sobre este serviço ou precisa de uma solução personalizada?",
            "contact_us": "Fale Conosco",
            "cybersecurity_title": "Segurança da Informação: Proteção Total para os Dados da Sua Empresa",
            "cybersecurity_description": "Na MSXP Connect, oferecemos soluções robustas de segurança da informação para proteger seus dados e garantir a integridade e confidencialidade das suas operações.",
            "learn_more_solutions": "Saiba Mais Sobre Nossas Soluções",
            "data_protection": "Proteção de Dados",
            "data_protection_description": "Medidas avançadas de segurança para proteger informações sensíveis contra acesso não autorizado e violações.",
            "continuous_monitoring": "Monitoramento Contínuo",
            "continuous_monitoring_description": "Monitoramento em tempo real para identificar e mitigar ameaças antes que possam prejudicar seu negócio.",
            "compliance_audit": "Conformidade e Auditoria",
            "compliance_audit_description": "Garantir que sua empresa esteja em conformidade com regulamentos e padrões de segurança.",
            "incident_response": "Resposta a Incidentes",
            "incident_response_description": "Planos de resposta a incidentes para minimizar impactos e restaurar operações rapidamente.",
            "digital_transformation_title": "Transformação Digital: Criando Websites e Soluções Visuais para Seu Negócio",
            "digital_transformation_description": "Na MSXP Connect, levamos seu negócio para o mundo digital com websites modernos e atraentes, além de soluções visuais como ícones personalizados e banners.",
            "explore_solutions": "Explore Nossas Soluções",
            "website_creation": "Criação de Websites",
            "website_creation_description": "Desenvolvimento de websites responsivos, otimizados e visualmente atraentes, alinhados à identidade da sua marca.",
            "icon_design": "Design de Ícones",
            "icon_design_description": "Desenvolvimento de ícones personalizados que refletem a identidade visual da sua empresa, garantindo navegação intuitiva.",
            "banners_elements": "Banners e Elementos Visuais",
            "banners_elements_description": "Criação de banners impactantes e elementos visuais que capturam a atenção do público e destacam sua mensagem.",
            "seo_optimization": "Otimização SEO",
            "seo_optimization_description": "Websites desenvolvidos com práticas de SEO para garantir visibilidade nos motores de busca e atrair visitantes qualificados.",
            "digital_transformation_question": "Quer saber mais sobre nossas soluções de transformação digital?",
            "it_consulting_title": "Consultoria de TI: Estratégias Personalizadas para o Sucesso Tecnológico da Sua Empresa",
            "it_consulting_description": "Na MSXP Connect, oferecemos consultoria de TI para otimizar seus processos tecnológicos e alinhar sua infraestrutura aos objetivos de negócios.",
            "learn_more_consulting": "Saiba Mais Sobre Nossas Consultorias",
            "analysis_diagnosis": "Análise e Diagnóstico",
            "analysis_diagnosis_description": "Identificação de pontos fortes e áreas de melhoria em sua infraestrutura de TI para maximizar a eficiência e reduzir custos.",
            "strategic_planning": "Planejamento Estratégico",
            "strategic_planning_description": "Desenvolvimento de planos de TI alinhados aos objetivos da sua empresa, garantindo que a tecnologia apoie seu crescimento.",
            "solution_implementation": "Implementação de Soluções",
            "solution_implementation_description": "Assistência na implementação de tecnologias e sistemas que otimizam seus processos e melhoram o desempenho operacional.",
            "support_monitoring": "Suporte e Monitoramento",
            "support_monitoring_description": "Serviços contínuos de suporte e monitoramento para garantir que sua infraestrutura de TI opere de forma eficiente e segura.",
            "consulting_question": "Tem perguntas ou precisa de uma solução de consultoria de TI?",
            "carousel_item_1": "Desenvolvimento e Gestão de Software",
            "carousel_item_2": "Segurança da Informação",
            "carousel_item_3": "Transformação Digital",
            "carousel_item_4": "Consultoria de TI",
            "cybersecurityPageTitle": "Proteja Seus Dados com Soluções de Segurança Avançadas",
            "cybersecurityPageDescription": "Na MSXP Connect, entendemos que a segurança da informação é crucial para proteger seu negócio contra ameaças cibernéticas. Nossas soluções são projetadas para garantir a integridade, confidencialidade e disponibilidade dos seus dados, mantendo sua empresa segura e em conformidade com os padrões regulatórios.",
            "securityServicesTitle": "Nossos Serviços de Segurança da Informação",
            "securityServicesDescription": "Nossos serviços de segurança da informação são projetados para proteger sua empresa.",
            "innovationTitle": "Inovação Contínua",
            "innovationDescription": "Identificamos vulnerabilidades e fornecemos recomendações para mitigar riscos.",
            "monitoringTitle": "Monitoramento",
            "monitoringDescription": "Monitoramos seus sistemas e respondemos rapidamente a incidentes.",
            "cloudSecurityTitle": "Segurança na Nuvem",
            "cloudSecurityDescription": "Protegemos seus dados na nuvem com segurança e conformidade.",
            "complianceTitle": "Conformidade",
            "complianceDescription": "Garantimos que sua empresa atenda aos padrões de segurança estabelecidos.",
            "whyChooseTitle": "Por Que Escolher a MSXP Connect para Segurança da Informação?",
            "protectionTitle": "Proteção 24/7",
            "protectionDescription": "Monitoramento contínuo para proteção completa de sua rede.",
            "customSolutionsTitle": "Soluções Personalizadas",
            "customSolutionsDescription": "Soluções de segurança adaptadas ao seu negócio.",
            "specializedTeamTitle": "Equipe Especializada",
            "specializedTeamDescription": "Especialistas em cibersegurança certificados.",
            "scalableSolutionsTitle": "Soluções Escaláveis",
            "scalableSolutionsDescription": "Nossas soluções crescem conforme sua empresa cresce.",
            "seamlessIntegrationTitle": "Integração Sem Fronteiras",
            "seamlessIntegrationDescription": "Integramos a segurança sem interromper suas operações.",
            "specializedSupportDescription": "Suporte especializado disponível sempre que você precisar.",
            "securityAspectsTitle": "Aspectos Chave da Segurança da Informação",
            "securityAspectsDescription": "Nossa abordagem à segurança da informação cobre todos os aspectos críticos para proteger seus dados e sistemas contra ameaças.",
            "dataProtectionTitle": "Proteção de Dados",
            "dataProtectionDescription": "Implementamos medidas para proteger seus dados contra acesso não autorizado e vazamentos.",
            "securityMonitoringTitle": "Monitoramento de Segurança",
            "securityMonitoringDescription": "Monitoramos continuamente suas redes e sistemas para detectar e responder rapidamente a ameaças.",
            "vulnerabilityManagementTitle": "Gerenciamento de Vulnerabilidades",
            "vulnerabilityManagementDescription": "Identificamos e corrigimos vulnerabilidades antes que possam ser exploradas por atacantes.",
            "backupRecoveryTitle": "Backup e Recuperação",
            "backupRecoveryDescription": "Realizamos backups regulares para garantir a continuidade dos negócios.",
            "networkSecurityTitle": "Segurança de Rede",
            "networkSecurityDescription": "Oferecemos proteção avançada para suas redes contra intrusões e ataques cibernéticos.",
            "accessControlTitle": "Controle de Acesso",
            "accessControlDescription": "Gerenciamos e controlamos o acesso aos seus sistemas para garantir a segurança.",
            "securityTrainingTitle": "Treinamento em Segurança",
            "securityTrainingDescription": "Oferecemos treinamento para sua equipe sobre melhores práticas e prevenção de ameaças.",
            "regulatoryComplianceTitle": "Conformidade Regulatória",
            "regulatoryComplianceDescription": "Garantimos que suas práticas de segurança estejam em conformidade com regulamentações.",
            "riskAssessmentTitle": "Avaliação de Risco",
            "riskAssessmentDescription": "Realizamos avaliações de risco para identificar fraquezas e melhorar continuamente.",
            "logoAltText": "Logo da MSXP Connect",
            "protectNetworkTitle": "Pronto para Proteger Sua Rede?",
            "protectNetworkDescription": "Não deixe seu negócio vulnerável a ataques cibernéticos. Com as soluções de segurança da MSXP Connect, você pode proteger seus dados, sua rede e seu negócio contra ameaças avançadas. Entre em contato conosco hoje para descobrir como podemos ajudar a manter sua empresa segura.",
            "requestConsultationButton": "Solicite uma Consulta Gratuita",
            "imageAltText": "Profissional de TI no computador",
            "itSolutionsTitle": "Soluções de Infraestrutura de TI",
            "itSolutionsDescription": "Aumente a eficiência e confiabilidade da sua rede com nossas soluções.",
            "learnMoreButton": "Saiba Mais",
            "highAvailability": "Alta disponibilidade e desempenho",
            "integratedSecurity": "Segurança integrada",
            "monitoring": "Monitoramento 24/7",
            "dedicatedSupport": "Suporte técnico dedicado",
            "chooseMSXPTitle": "Por Que Escolher a MSXP Connect para Segurança da Informação?",
            "dataCenterManagementTitle": "Gerenciamento de Data Center",
            "dataCenterManagementDesc": "Nossas soluções de gerenciamento de data center são projetadas para otimizar o desempenho e a segurança dos seus dados.",
            "constantMonitoring": "Monitoramento constante para identificar e resolver problemas rapidamente.",
            "backupSystems": "Sistemas de backup para garantir operação contínua em caso de falha.",
            "energyReduction": "Redução do consumo de energia para operações mais sustentáveis.",
            "resourceAddition": "Adição de recursos conforme sua empresa cresce.",
            "corporateNetworksTitle": "Redes Corporativas",
            "corporateNetworksDesc": "Desenvolvemos redes corporativas que garantem comunicação eficiente e segura entre seus departamentos, com soluções escaláveis.",
            "effectiveProtection": "Proteção eficaz contra ameaças e acesso não autorizado.",
            "failoverSystems": "Garantia de acesso contínuo com sistemas de failover.",
            "globalIntegration": "Integração de redes em várias localidades ao redor do mundo.",
            "centralizedAdmin": "Administração centralizada para manutenção e controle mais fáceis.",
            "internetProvidersTitle": "Provedores de Internet",
            "internetProvidersDesc": "Oferecemos suporte especializado para provedores de internet, garantindo conexões rápidas, seguras e estáveis.",
            "highPerformance": "Desempenho de Rede Alto: Conexões rápidas e com baixa latência.",
            "qualifiedAssistance": "Assistência qualificada para problemas e manutenção.",
            "proactiveMaintenance": "Manutenção Proativa: Ajustes regulares para evitar falhas.",
            "continuousAnalysis": "Análise contínua para garantir a estabilidade da rede.",
            "highPerformanceInfrastructureTitle": "Impulsione Seu Negócio com Infraestrutura de Alto Desempenho",
            "highPerformanceInfrastructureDesc": "Na MSXP Connect, entendemos que a infraestrutura de TI é a espinha dorsal de qualquer organização moderna. Por isso, nossas soluções são projetadas para proporcionar não apenas confiabilidade e desempenho, mas também flexibilidade para crescer com seu negócio. Com uma abordagem focada em segurança, nossas soluções protegem seus dados e garantem conformidade regulatória, enquanto facilitam a inovação e expansão. Além disso, nosso suporte técnico dedicado e monitoramento proativo garantem que sua infraestrutura esteja sempre otimizada e funcionando eficientemente. Independentemente do tamanho ou complexidade do seu negócio, estamos aqui para ajudar a transformar suas operações e alcançar novos patamares de sucesso.",
            "securityServicesDesc": "Nossos serviços de segurança da informação são projetados para proteger seu negócio.",
            "networkOptimizationTitle": "Otimização de Rede",
            "networkOptimizationDesc": "Aumente o desempenho da sua rede com nossas soluções de otimização. Garantimos conectividade contínua e rápida.",
            "specializedSupportTitle": "Suporte Técnico Especializado",
            "specializedSupportDesc": "Conte com nossa equipe de especialistas para suporte técnico dedicado e manutenção proativa.",
            "transformITInfrastructureTitle": "Pronto para Transformar Sua Infraestrutura de TI?",
            "transformITInfrastructureDesc": "A infraestrutura de TI é a base sobre a qual seu negócio se sustenta. Em um mundo onde agilidade e eficiência são essenciais, uma infraestrutura robusta e bem gerida pode fazer a diferença entre sucesso e fracasso. Na MSXP Connect, oferecemos soluções de infraestrutura que atendem não apenas às necessidades atuais.",
            "consultButton": "Solicitar uma Consulta Gratuita",
            "digitalTransformationBadge": "Transformação Digital",
            "digitalTransformationTitle": "Transforme Sua Presença Digital",
            "digitalTransformationDesc": "Explore nossas soluções de transformação digital projetadas para elevar sua marca no ambiente online.",
            "logoIdentity": "Logotipo e Identidade Visual",
            "customSoftware": "Software Personalizado",
            "onlinePresence": "Presença Online",
            "digitalTransformationServicesTitle": "Serviços de Transformação Digital",
            "digitalTransformationServicesDesc": "Explore nossa gama completa de serviços de transformação digital, projetados para modernizar sua presença online, otimizar suas operações e impulsionar o crescimento dos negócios.",
            "webDevelopment": "Desenvolvimento de Websites",
            "webDevelopmentDesc": "Crie websites modernos e funcionais que reflitam sua marca e atendam às necessidades dos usuários.",
            "logoDesign": "Design de Logotipos",
            "logoDesignDesc": "Desenvolva logotipos que capturem a identidade da sua empresa e se destaquem no mercado.",
            "digitalMarketing": "Marketing Digital",
            "digitalMarketingDesc": "Implemente estratégias eficazes para aprimorar sua presença online e engajar seu público-alvo.",
            "mobileApps": "Aplicativos Móveis",
            "mobileAppsDesc": "Crie aplicativos móveis personalizados para melhorar a acessibilidade e a interação com seus clientes.",
            "seoOptimization": "Otimização SEO",
            "seoOptimizationDesc": "Aumente a visibilidade do seu site nos motores de busca para atrair novos visitantes.",
            "dataAnalysis": "Análise de Dados",
            "dataAnalysisDesc": "Utilize dados para gerar insights valiosos e tomar decisões informadas sobre suas estratégias digitais.",
            "systemIntegration": "Integração de Sistemas",
            "systemIntegrationDesc": "Integre várias plataformas e sistemas para melhorar a eficiência operacional e a comunicação entre eles.",
            "consulting": "Consultoria",
            "consultingDesc": "Orientação especializada para ajudar sua empresa a implementar e gerenciar mudanças digitais eficazes.",
            "ourExpertiseTitle": "Nossa Especialização",
            "ourExpertiseDesc": "No mundo hiperconectado de hoje, sua presença digital é mais do que apenas uma vitrine online; é o coração pulsante da sua marca. A MSXP Connect está aqui para transformar essa presença de maneiras inovadoras e impactantes, oferecendo soluções personalizadas que não apenas destacam sua marca, mas também a posicionam estrategicamente para o sucesso.",
            "customWebsites": "Desenvolvimento de Websites",
            "customWebsitesDesc": "Na MSXP Connect, criamos websites sob medida que refletem a identidade única da sua marca. Oferecemos soluções abrangentes que garantem design atraente, funcionalidade impecável e otimização para todos os dispositivos, proporcionando uma experiência excepcional para seus usuários.",
            "customSoftwareDesc": "Com a MSXP Connect, o desenvolvimento de software é personalizado para atender às suas necessidades específicas. De aplicações empresariais a soluções personalizadas, nossa equipe utiliza as tecnologias mais recentes para criar software robusto e eficiente.",
            "onlinePresenceDesc": "Na era digital, a presença online de uma empresa é crucial para seu sucesso. A MSXP Connect oferece uma abordagem completa para construir e gerenciar sua presença online, incluindo estratégias de marketing digital, otimização SEO e gestão de mídias sociais.",
            "operationalEfficiency": "Eficiência Operacional Aprimorada",
            "operationalEfficiencyDesc": "Automatize processos e reduza erros humanos com soluções digitais, aumentando a eficiência e a produtividade em todas as áreas do seu negócio.",
            "customerExperience": "Experiência do Cliente Aprimorada",
            "customerExperienceDesc": "Ofereça uma experiência de usuário mais intuitiva e personalizada com ferramentas digitais que melhoram o atendimento e as interações com os clientes.",
            "advancedAnalytics": "Análise e Insights Avançados",
            "advancedAnalyticsDesc": "Utilize ferramentas de análise de dados para obter insights profundos sobre o comportamento dos clientes e o desempenho dos negócios, permitindo decisões mais informadas.",
            "agilityFlexibility": "Agilidade e Flexibilidade",
            "agilityFlexibilityDesc": "Adapte-se rapidamente às mudanças de mercado e novas oportunidades com soluções digitais que oferecem agilidade e flexibilidade operacional.",
            "costReduction": "Redução de Custos",
            "costReductionDesc": "Minimize os custos operacionais por meio da automação e eficiência proporcionadas pelas soluções digitais, liberando recursos para outras áreas.",
            "increasedCompetitiveness": "Aumento da Competitividade",
            "increasedCompetitivenessDesc": "Ganhe uma vantagem competitiva no mercado com tecnologias de ponta e estratégias digitais que destacam sua empresa da concorrência.",
            "responsiveDesign": "Design Responsivo",
            "responsiveDesignDescription": "Websites que se adaptam a dispositivos, garantindo uma boa experiência.",
            "customSolutions": "Soluções Personalizadas",
            "maintenanceSupport": "Manutenção e Suporte",
            "maintenanceSupportDescription": "Suporte contínuo para manter seu site funcionando sem problemas e atualizado.",
            "transformBusiness": "Transforme a Forma Como Você Faz Negócios",
            "boostBusiness": "Impulsione Seu Negócio com Nossa Transformação Digital",
            "digitalTransformationDescription": "A transformação digital é fundamental para modernizar suas operações e aproveitar as tecnologias emergentes. Na MSXP Connect, oferecemos soluções abrangentes que cobrem desde o desenvolvimento de websites até a criação de logotipos e estratégias online personalizadas, garantindo que sua empresa se mantenha à frente no mercado digital.",
            "digitalTransformationSpecialists": "Nossos especialistas em transformação digital trabalham com você para implementar soluções inovadoras que melhoram a eficiência, a experiência do cliente e a presença online da sua marca.",
            "customWebSolutions": "Soluções web personalizadas para atender às necessidades específicas do seu negócio.",
            "intuitiveInterfaces": "Criação de interfaces intuitivas e visuais que aprimoram a experiência do usuário.",
            "digitalIntegration": "Integração de ferramentas e plataformas digitais que otimizam suas operações.",
            "seoPractices": "Aumente sua visibilidade online com práticas eficazes de SEO.",
            "logoDesignDescription": "Criação de logotipos impactantes que representam a identidade da sua marca.",
            "colorPalette": "Paleta de Cores",
            "colorPaletteDescription": "Desenvolvimento de uma paleta de cores coesa que reflete a personalidade da sua marca.",
            "typography": "Tipografia",
            "typographyDescription": "Escolha de fontes que complementam a identidade visual e transmitem a mensagem certa.",
            "brandMaterials": "Materiais de Marca",
            "brandMaterialsDescription": "Criação de materiais gráficos, como cartões de visita e papelaria, que reforçam sua identidade visual.",
            "transformYourBrand": "Transforme Sua Marca com Identidade Visual",
            "developImpactfulVisualIdentity": "Desenvolva uma Identidade Visual Impactante para Seu Negócio",
            "visualIdentityDescription": "A identidade visual é crucial para a percepção da sua marca. Na MSXP Connect, criamos logotipos e identidades visuais que fazem sua marca se destacar e comunicar eficazmente seus valores. Desde o design de logotipos até a escolha de uma paleta de cores e tipografia, trabalhamos para garantir que sua marca tenha uma presença memorável.",
            "visualIdentitySpecialists": "Nossos designers especializados trabalham com você para criar uma identidade visual que não apenas atraia a atenção, mas também estabeleça uma conexão duradoura com seu público-alvo.",
            "customLogoDesign": "Designs de logotipos personalizados que refletem a essência da sua marca.",
            "harmoniousColorPalettes": "Paletas de cores harmoniosas que ajudam a criar uma identidade visual consistente.",
            "carefullySelectedTypography": "Tipografia cuidadosamente selecionada que complementa a identidade visual.",
            "brandReinforcementMaterials": "Materiais gráficos que reforçam a imagem da sua marca em todos os pontos de contato.",
            "softwareDevelopment": "Desenvolvimento de Software",
            "softwareDevelopmentDescription": "Criação de soluções de software personalizadas que atendem às suas necessidades específicas.",
            "systemIntegrationDescription": "Integração eficiente de software com sistemas existentes para otimizar processos.",
            "cloudSolutions": "Soluções na Nuvem",
            "cloudSolutionsDescription": "Desenvolvimento e gerenciamento de aplicações e serviços na nuvem.",
            "securityAndSupport": "Segurança e Suporte",
            "securityAndSupportDescription": "Segurança garantida e suporte contínuo para o bom funcionamento do seu software.",
            "transformYourProcesses": "Transforme Seus Processos com Software Personalizado",
            "enhanceYourBusiness": "Aprimore Seu Negócio com Software Personalizado",
            "softwareDescription": "Software personalizado oferece soluções específicas para atender às suas necessidades empresariais únicas. Na MSXP Connect, desenvolvemos software sob medida que se integra perfeitamente aos seus processos, aumentando a eficiência e melhorando os resultados. Desde o desenvolvimento até a integração e suporte, garantimos que seu software esteja sempre alinhado aos objetivos do seu negócio.",
            "softwareExperts": "Nossos especialistas trabalham de perto com você para entender suas necessidades e criar soluções de software que impulsionam seu sucesso.",
            "customSoftwareDevelopment": "Desenvolvimento de software personalizado para atender às suas necessidades únicas.",
            "robustSecurity": "Segurança robusta e suporte contínuo para garantir o desempenho ideal do seu software.",
            "socialMediaManagement": "Gestão de Mídias Sociais",
            "performanceAnalysis": "Análise de Desempenho",
            "strengthenDigitalPresence": "Fortaleça Sua Presença Digital",
            "maximizeVisibility": "Maximize Sua Visibilidade com Estratégias Online Eficazes",
            "webDevelopmentDescription": "Criação de websites e páginas que maximizam a visibilidade e a interação online.",
            "seoOptimizationDescription": "Estratégias para melhorar seu ranking nos motores de busca e aumentar o tráfego.",
            "socialMediaManagementDescription": "Criação e gestão de perfis e campanhas nas principais plataformas sociais.",
            "performanceAnalysisDescription": "Monitoramento e análise de dados para ajustar estratégias e melhorar resultados.",
            "digitalPresenceDescription": "Uma presença online sólida é crucial para o sucesso nos negócios modernos. Na MSXP Connect, oferecemos soluções completas para estabelecer e fortalecer sua presença digital. Desde o desenvolvimento de websites impactantes até estratégias avançadas de SEO e gestão de mídias sociais, trabalhamos para garantir que sua marca se destaque e alcance seu público-alvo.",
            "digitalMarketingExperts": "Nossos especialistas em marketing digital e desenvolvimento web colaboram com você para criar e executar estratégias que aumentam a visibilidade, engajam seu público e impulsionam o crescimento dos negócios.",
            "impactfulWebsiteDevelopment": "Desenvolvimento de websites que garantem uma presença online marcante e funcional.",
            "seoOptimizationService": "Otimização SEO para melhorar a visibilidade e atrair visitantes qualificados.",
            "effectiveSocialMediaManagement": "Gestão eficaz de mídias sociais para engajar e expandir seu público.",
            "detailedPerformanceAnalysis": "Análise detalhada de desempenho para ajustar estratégias e melhorar resultados.",
            "transformChallengesIntoOpportunities": "Transforme Seus Desafios em Oportunidades com Nossa Consultoria de TI",
            "itConsultingDescription": "Na MSXP Connect, oferecemos consultoria especializada em Tecnologia da Informação para ajudar sua empresa a superar desafios tecnológicos e aproveitar totalmente as oportunidades digitais.",
            "ourITConsultingServices": "Nossos Serviços de Consultoria em TI",
            "exploreOurRangeOfITServices": "Explore nossa gama de serviços de consultoria em TI",
            "infrastructureAnalysis": "Análise de Infraestrutura",
            "evaluateYourCurrentInfrastructure": "Avalie sua infraestrutura atual para identificar áreas de melhoria e otimização.",
            "strategicPlanning": "Planejamento Estratégico",
            "developTechPlansAlignedWithBusiness": "Desenvolva planos tecnológicos alinhados com seus objetivos de negócios.",
            "projectManagement": "Gerenciamento de Projetos",
            "manageITProjectsEfficiently": "Gerencie projetos de TI de forma eficiente e dentro dos prazos estabelecidos.",
            "informationSecurity": "Segurança da Informação",
            "implementSecurityMeasures": "Implemente medidas de segurança para proteger seus dados e sistemas.",
            "processOptimization": "Otimização de Processos",
            "improveEfficiencyWithOptimizedProcesses": "Melhore a eficiência do seu negócio com processos otimizados.",
            "teamTraining": "Treinamento de Equipe",
            "empowerYourTeamWithTraining": "Capacite sua equipe com treinamento personalizado em novas tecnologias.",
            "cloudConsulting": "Consultoria em Nuvem",
            "adoptCloudSolutionsForFlexibility": "Adote soluções em nuvem que aumentam a flexibilidade e escalabilidade.",
            "technicalSupport": "Suporte Técnico",
            "getOngoingTechnicalSupport": "Receba suporte técnico contínuo para resolver problemas rapidamente.",
            "maximizeITPotential": "Maximize o Potencial da Sua TI com Nossa Consultoria Especializada",
            "comprehensivePersonalizedSolutions": "Nossa consultoria em TI oferece soluções abrangentes e personalizadas para atender às necessidades específicas do seu negócio.",
            "detailedInfrastructureAssessment": "Avaliação detalhada da sua infraestrutura atual para identificar áreas de melhoria.",
            "technologyPlansAlignedWithGoals": "Desenvolvimento de planos tecnológicos alinhados com seus objetivos de negócios.",
            "coordinationAndExecutionOfProjects": "Coordenação e execução de projetos de TI com foco em resultados e prazos.",
            "robustSecurityMeasures": "Implementação de medidas de segurança robustas para proteger seus dados e sistemas.",
            "operationalEfficiencyImprovements": "Melhoria da eficiência operacional por meio de automação e aprimoramento de processos.",
            "personalizedTeamTraining": "Treinamento personalizado para sua equipe em novas tecnologias e práticas de TI.",
            "cloudAdoptionAndManagement": "Adoção e gerenciamento de soluções em nuvem que aumentam a flexibilidade e escalabilidade.",
            "rapidEffectiveTechnicalSupport": "Suporte técnico rápido e eficaz para resolver problemas e minimizar interrupções.",
            "systemsIntegration": "Integração de diversos sistemas para garantir uma operação coesa e eficiente.",
            "detailedReportingAndAnalysis": "Fornecimento de relatórios detalhados e análises para monitorar e melhorar o desempenho de TI.",
            "consultingSolutionsForEfficientFuture": "Soluções de Consultoria para um Futuro Mais Eficiente",
            "transformHowYourCompanyUsesTechnology": "Nossa consultoria em TI é projetada para transformar a maneira como sua empresa utiliza a tecnologia.",
            "evaluationAndPlanning": "Avaliação e Planejamento",
            "completeAnalysisAndStrategicPlan": "Realizamos uma análise completa da sua infraestrutura de TI e desenvolvemos um plano estratégico para otimizar seu ambiente tecnológico.",
            "implementationAndManagement": "Implementação e Gestão",
            "manageTechSolutionsAndContinuousMonitoring": "Gerenciamos a implementação de soluções tecnológicas e garantimos monitoramento contínuo para garantir que tudo funcione de forma eficiente.",
            "supportAndTraining": "Suporte e Treinamento",
            "dedicatedTechSupportAndProactiveMaintenance": "Conte com nossa equipe de especialistas para suporte técnico dedicado e manutenção proativa.",
            "readyToTransformTechnology": "Pronto para Transformar Sua Tecnologia com Nossa Consultoria de TI?",
            "digitalTransformationKeyToInnovation": "A transformação digital é a chave para inovação e crescimento no mundo atual. Nossos especialistas estão prontos para ajudar você a atualizar e aprimorar todos os aspectos da sua presença online, desde o desenvolvimento de sites e design de logotipos até a otimização de suas estratégias digitais.",
            "requestFreeConsulting": "Solicite uma Consultoria Gratuita",
            "optimizeAndManageSoftwareResources": "Otimize e Gerencie Seus Recursos de Software de Forma Eficiente",
            "msxpConnectSoftwareSolutions": "Na MSXP Connect, oferecemos soluções abrangentes de gerenciamento de software que garantem que suas aplicações e sistemas estejam sempre otimizados e alinhados com suas necessidades de negócios.",
            "softwareImplementation": "Implementação de Software",
            "softwareImplementationDescription": "Auxiliamos na seleção das melhores soluções de software para suas necessidades e as implementamos para garantir uma integração suave e eficaz com seus sistemas existentes.",
            "licenseManagementDescription": "Gerenciamos e monitoramos licenças de software para garantir conformidade e otimizar custos, evitando problemas legais e assegurando o uso adequado das licenças.",
            "maintenanceAndSupport": "Manutenção e Suporte",
            "maintenanceAndSupportDescription": "Oferecemos suporte técnico contínuo e manutenção regular para manter seu software funcionando perfeitamente e resolver quaisquer problemas que possam surgir.",
            "teamTableAlt": "tabela de equipe",
            "perfectChoiceAndIntegrity": "Escolha Perfeita e Integridade",
            "findAndImplementBestSolutions": "Encontre e Implemente as Melhores Soluções",
            "ourSoftwareSelectionProcess": "Nosso processo de seleção de software é minucioso e personalizado para garantir que você escolha soluções que melhor atendam às suas necessidades específicas. Avaliamos vários fornecedores e opções para garantir a melhor combinação de funcionalidade, custo e compatibilidade.",
            "afterSelectionManagement": "Após a seleção, gerenciamos a implementação do software para garantir uma integração tranquila com seus sistemas existentes. Trabalhamos para minimizar qualquer interrupção nas operações e garantir que sua equipe possa começar a usar o novo software de forma rápida e eficiente.",
            "complianceAndSavings": "Conformidade e Economia",
            "efficientLicenseManagement": "Gerenciamento Eficiente de Licenças",
            "manageAndMonitorLicenses": "Gerenciamos e monitoramos todas as suas licenças de software para garantir conformidade legal e maximizar o valor do seu investimento. Mantemos registros detalhados e atualizados, evitando problemas de auditoria e garantindo o uso adequado das licenças.",
            "continuousLicenseAdjustment": "Nossa equipe revisa continuamente suas necessidades e ajusta as licenças conforme necessário para otimizar custos. Isso inclui a renegociação de contratos e a identificação de oportunidades de economia, garantindo que você pague apenas pelo que realmente usa.",
            "techAlt": "tecnologia",
            "techTableAlt": "tabela de tecnologia",
            "specializedMaintenanceAndSupport": "Manutenção e Suporte Especializados",
            "proactiveMaintenanceAndContinuousSupport": "Manutenção Proativa e Suporte Contínuo",
            "continuousTechnicalSupport": "Oferecemos suporte técnico contínuo para resolver rapidamente quaisquer problemas que possam surgir com seu software. Nossa abordagem proativa inclui monitoramento e manutenção regulares para identificar e resolver problemas antes que eles afetem suas operações.",
            "regularMaintenance": "Além do suporte técnico, realizamos manutenção regular para garantir que seu software funcione sem problemas e esteja atualizado com os patches e melhorias mais recentes. Isso minimiza o risco de falhas e maximiza a eficiência do seu ambiente de TI.",
            "softwareManagementFeaturesAndBenefits": "Características e Benefícios do Gerenciamento de Software",
            "exploreSoftwareManagementBenefits": "Explore as diversas características e benefícios que nosso gerenciamento de software oferece para otimizar suas operações e maximizar seu investimento em tecnologia.",
            "efficientSelection": "Seleção Eficiente",
            "chooseBestSolutions": "Escolha as melhores soluções de software para suas necessidades.",
            "quickImplementation": "Implementação Rápida",
            "integrateWithoutDisruptions": "Integre novo software sem interromper as operações.",
            "licenseManagement": "Gerenciamento de Licenças",
            "controlLicenses": "Gerencie licenças para evitar problemas legais e economizar custos.",
            "ongoingAssistance": "Assistência Contínua para resolver problemas e dúvidas.",
            "regularUpdates": "Atualizações Regulares",
            "softwareUpToDate": "Mantenha seu software atualizado com as versões mais recentes.",
            "customTraining": "Treinamento Personalizado",
            "continuousEvaluation": "Avaliação contínua para melhorar o desempenho do software.",
            "specializedConsulting": "Consultoria Especializada",
            "softwareSolutionsGuidance": "Orientação sobre soluções e estratégias de software.",
            "transformSoftwareManagement": "Transforme a Forma Como Sua Empresa Gerencia Software",
            "efficientSoftwareManagement": "Gerenciamento eficiente de software é crucial para maximizar seu retorno sobre o investimento e garantir que sua empresa esteja equipada para enfrentar desafios tecnológicos com agilidade. Na MSXP Connect, oferecemos uma abordagem holística para o gerenciamento de software, cobrindo desde a seleção e implementação até a manutenção e suporte contínuos. Nossa equipe de especialistas trabalha em estreita colaboração com você para entender suas necessidades específicas e fornecer soluções personalizadas que otimizam o desempenho e a funcionalidade do seu software.",
            "adoptingOurPractices": "Ao adotar nossas práticas de gerenciamento, você não apenas melhora a eficiência operacional e a segurança, mas também obtém uma visão clara dos seus recursos tecnológicos, permitindo decisões mais informadas e estratégicas. Descubra como nossa expertise pode transformar sua abordagem ao software e impulsionar o sucesso da sua empresa.",
            "revolutionizeSoftwareManagement": "Pronto para Revolucionar o Gerenciamento de Software?",
            "specializedSolutions": "Na MSXP Connect, estamos prontos para levar o gerenciamento de software para o próximo nível. Nossas soluções especializadas garantem o gerenciamento eficiente de todos os seus recursos de software, desde a seleção e implementação até o suporte e otimização contínuos.",
            "softwareDevelopmentManagement": "Desenvolvimento e Gerenciamento de Software",
            "comprehensiveSolutions": "Na MSXP Connect, oferecemos soluções abrangentes para o desenvolvimento e gerenciamento de software, projetadas para atender às necessidades específicas do seu negócio.",
            "requirementsAnalysis": "Análise de Requisitos",
            "requirementsAnalysisDesc": "Coleta e documentação das necessidades do cliente.",
            "designPrototyping": "Design e Prototipagem",
            "designPrototypingDesc": "Criação de protótipos para visualização e ajustes.",
            "agileDevelopment": "Desenvolvimento Ágil",
            "agileDevelopmentDesc": "Metodologia ágil para entrega rápida e feedback contínuo.",
            "implementation": "Implementação",
            "implementationDesc": "Lançamento do software e treinamento da equipe.",
            "customSoftwareSolutions": "Soluções de Software Personalizadas",
            "completeServiceRange": "Oferecemos uma gama completa de serviços para garantir que o software da sua empresa seja desenvolvido e gerenciado de maneira eficiente e personalizada.",
            "quickDelivery": "Entrega Rápida",
            "continuousFeedback": "Feedback Contínuo",
            "seamlessIntegration": "Integração Sem Costura",
            "efficiencyImprovement": "Melhoria da Eficiência",
            "continuousSupport": "Suporte Contínuo",
            "24x7Support": "Suporte 24x7",
            "proactiveMonitoring": "Monitoramento Proativo",
            "regularMaintenanceFor": "Manutenção Regular para",
            "continuousEnhancements": "Aprimoramentos Contínuos",
            "transformYourBusiness": "Transforme Seu Negócio com Nossas Soluções de Software",
            "eachServiceOptimized": "Cada serviço é projetado para otimizar processos, aumentar a produtividade e garantir que você se mantenha à frente em um mercado em constante evolução.",
            "customDevelopment": "Desenvolvimento Personalizado",
            "customDevelopmentDescription": "Criamos software sob medida para as necessidades da sua empresa. Da concepção à implementação, garantimos que cada detalhe seja ajustado para otimizar seus processos e impulsionar o crescimento.",
            "projectManagementDescription": "Gerenciamos todo o ciclo de vida do seu projeto de software, garantindo que os prazos sejam cumpridos e os entregáveis sejam de alta qualidade. Nossa abordagem meticulosa minimiza riscos e maximiza o ROI.",
            "continuousMaintenance": "Manutenção Contínua",
            "continuousMaintenanceDescription": "Oferecemos suporte e manutenção contínuos para garantir que seu software funcione sem problemas. Atualizações e melhorias regulares mantêm você competitivo e seguro no mercado.",
            "msxpConnectServices": "Na MSXP Connect, oferecemos uma ampla gama de serviços que cobrem todas as etapas do ciclo de vida do software.",
            "specializedConsultingDescription": "Nossos consultores experientes trabalham com você para entender suas necessidades e fornecer soluções de software que se alinhem perfeitamente com seus objetivos estratégicos.",
            "agileDevelopmentDescription": "Usamos metodologias ágeis para desenvolver software de forma rápida e eficiente, permitindo ajustes contínuos para atender melhor às necessidades do seu negócio.",
            "softwareSecurity": "Segurança de Software",
            "softwareSecurityDescription": "Implementamos medidas rigorosas de segurança durante todo o processo de desenvolvimento para proteger seus dados e garantir conformidade com regulamentações.",
            "continuousSupportDescription": "Oferecemos suporte técnico dedicado para manter seu software funcionando perfeitamente, com soluções rápidas para quaisquer problemas que surgirem.",
            "updatesAndMaintenance": "Atualizações e Manutenção",
            "updatesAndMaintenanceDescription": "Mantemos seu software atualizado com as tecnologias e melhorias mais recentes, garantindo que você se mantenha competitivo e seguro no mercado.",
            "readyToRevolutionizeSoftwareManagement": "Pronto para Revolucionar o Gerenciamento de Software?",
            "msxpConnectReadyDescription": "Na MSXP Connect, estamos prontos para levar o gerenciamento de software a um novo nível. Nossas soluções especializadas garantem o gerenciamento eficiente de todos os seus recursos de software, desde a seleção e implementação até o suporte e otimização contínuos.",
            "frequentlyAskedQuestions": "Perguntas Frequentes Sobre Nossos Serviços de Software",
            "faqDescription": "Para ajudá-lo a entender melhor nossos serviços e como podemos contribuir para o sucesso do seu negócio, respondemos às perguntas mais comuns que recebemos de nossos clientes.",
            "whatIsCustomSoftwareDevelopment": "O que é desenvolvimento de software personalizado?",
            "customSoftwareDevelopmentDescription": "Desenvolvimento de software personalizado refere-se à criação de soluções de software adaptadas para atender às necessidades específicas de uma empresa ou organização. Em vez de usar software pré-fabricado, criamos ferramentas sob medida que se alinham perfeitamente com seus processos e objetivos de negócios.",
            "benefitsOfSoftwareIntegration": "Quais são os benefícios de integrar um novo software com sistemas existentes?",
            "softwareIntegrationBenefits": "Integrar um novo software com sistemas existentes permite maior eficiência, automação e centralização das informações. Reduz a redundância de processos, melhora a comunicação entre diferentes áreas da empresa e simplifica a gestão operacional, resultando em economia de tempo e recursos.",
            "howDoesTechnicalSupportWork": "Como funciona o suporte técnico contínuo oferecido pela MSXP Connect?",
            "technicalSupportDescription": "O suporte técnico contínuo da MSXP Connect garante que sua solução de software esteja sempre atualizada e funcionando sem problemas. Nossa equipe está disponível para resolver problemas, fornecer atualizações e fazer ajustes conforme necessário, mantendo a operação do seu sistema eficiente e segura a longo prazo.",
            "whatIsSoftwareProjectManagement": "O que é gerenciamento de projetos de software?",
            "softwareProjectManagementDescription": "Gerenciamento de projetos de software envolve o planejamento, execução e monitoramento de todas as etapas do desenvolvimento de software. Na MSXP Connect, seguimos metodologias ágeis para garantir que o projeto seja entregue no prazo, dentro do orçamento e com a qualidade esperada, com total transparência em cada etapa.",
            "howDoesMSXPConnectEnsureDataSecurity": "Como a MSXP Connect garante a segurança dos meus dados?",
            "dataSecurityDescription": "A segurança dos seus dados é nossa principal prioridade. Utilizamos práticas avançadas de segurança, como criptografia, monitoramento contínuo e backups regulares para proteger seus dados contra ameaças externas. Além disso, seguimos rigorosamente as melhores práticas de conformidade para garantir que sua solução atenda aos padrões de privacidade e segurança de dados.",
            "aboutOurCompany": "Sobre Nossa Empresa",
            "welcomeMessage": "Bem-vindo à MSXP Connect",
            "ourHistory": "Nossa História",
            "getToKnowUs": "Conheça a MSXP Connect",
            "exploreOurServices": "Explore Nossos Serviços",
            "teamImageAlt": "Membro da equipe trabalhando no computador",
            "ourExpertiseDescription": "A MSXP Connect é uma empresa líder em soluções tecnológicas, especializada em desenvolvimento de software, segurança da informação e infraestrutura de TI. Com uma equipe experiente e dedicada, oferecemos serviços personalizados para ajudar empresas a atingir seus objetivos e otimizar seus processos.",
            "ourCommitmentTitle": "Nosso Compromisso",
            "ourCommitmentDescription": "Nosso compromisso com a inovação e qualidade nos permite oferecer soluções eficazes adaptadas às necessidades específicas de cada cliente. Na MSXP Connect, sua satisfação é nossa prioridade, e trabalhamos incansavelmente para garantir o sucesso de cada projeto.",
            "missionValuesVision": "Missão, Valores e Visão para um Futuro de Sucesso",
            "commitmentTitle": "Compromisso",
            "commitmentDescription": "Nosso compromisso garante que cada projeto seja executado com a máxima precisão e excelência, atendendo às expectativas de nossos clientes.",
            "customerFocusTitle": "Foco no Cliente",
            "customerFocusDescription": "Colocamos nossos clientes no centro de tudo o que fazemos, ajustando nossos serviços para atender às suas necessidades e superar suas expectativas.",
            "dedicatedTeamTitle": "Equipe Dedicada",
            "dedicatedTeamDescription": "Nossa equipe é composta por profissionais apaixonados e especializados, comprometidos em entregar resultados excepcionais e impulsionar o sucesso de nossos clientes.",
            "readyToAnswer": "Estamos prontos para responder todas as suas perguntas e resolver quaisquer problemas que você possa ter conosco.",
            "phone": "Telefone",
            "callNow": "Ligue Agora",
            "sendEmail": "Envie um Email",
            "address": "Endereço",
            "addressDescription": "Sediada em Londres, Reino Unido.",
            "viewOnMap": "Ver no Mapa",
            "preferToFillOutForm": "Ou, se preferir, preencha o formulário abaixo",
            "name": "Nome",
            "namePlaceholder": "Digite seu nome",
            "nameValidation": "Por favor, insira seu nome.",
            "email": "Email",
            "emailPlaceholder": "Digite seu email",
            "emailValidation": "Por favor, insira um email válido.",
            "message": "Mensagem",
            "messagePlaceholder": "Como podemos ajudar?",
            "messageValidation": "Por favor, insira uma mensagem.",
            "sendMessage": "Enviar Mensagem",
            "requiredFields": "Todos os campos são obrigatórios. Ao enviar o formulário, você concorda com os Termos e Condições e a Política de Privacidade.",
            "contractBadge": "Contrato",
            "contractTitle": "Veja um contrato transparente que preparamos para você",
            "contractDescription": "Estamos comprometidos em fornecer um serviço de qualidade e um contrato justo para ambas as partes.",
            "privacyPolicy": "Política de Privacidade",
            "copyrightText": `Copyright © ${new Date().getFullYear()} MSXP Connect. Todos os direitos reservados.`,
        }
    };

    return language;
};

export default language();